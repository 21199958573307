<template>
    <div>
        <v-container fluid>
            <v-row align="center">
                <v-col cols="12" sm="6">
                    <v-select v-model="visiblePolicies" :items="policiesEntries" label="Select Policies to include" multiple
                        hint="Select the policies that match your company" persistent-hint>
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggle">
                                <v-list-item-action>
                                    <v-icon :color="visiblePolicies.length > 0 ? 'indigo darken-4' : ''">
                                        {{ icon }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>

                    </v-select>
                </v-col>
            </v-row>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Sales Commission Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Sales Commission Policy" id="SalesCommissionPolicy">
                <v-form ref="CommissionPolicyForm" v-model="validCommissionPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasCommissionPolicy" label="Has Sales Commission Policy"
                                    color="success" @change="hasCommissionPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.commissionBasis" :items="CommissionBasisOptions"
                                    :rules="[rules.required]" label="Commission Basis" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.commissionModel" :items="commissionModelOptions"
                                    :rules="[rules.required]" label="Commission Model" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.freqOfPayment" :items="freqOfPaymentOptions"
                                    :rules="[rules.required]" label="Frequency Of Payment"
                                    :required="form.hasCommissionPolicy" :disabled="!form.hasCommissionPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.otherFreqOfPayment" :rules="[rules.otherFreqOfPayment]"
                                    label="Other Payment" :required="form.freqOfPayment == 5"
                                    :disabled="form.freqOfPayment != 5">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.schemeModel" :items="schemeModelOptions" :rules="[rules.required]"
                                    label="Scheme Model" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.schemeBoundryMin" min=0 :rules="[rules.required,
                                rules.schemeBoundry, rules.schemeBoundryMin]"
                                    label="Scheme lower  boundary thresholds" type="number"
                                    :required="form.hasCommissionPolicy" :disabled="!form.hasCommissionPolicy">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.schemeBoundryMax" min=0
                                    :rules="[rules.required, rules.schemeBoundry]" label="Scheme upper  boundary thresholds"
                                    type="number" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.annualSalesCommission" min=0 :rules="[rules.schemeBoundry]"
                                    label="Annual Sales Commission" type="number" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.projectBasedBonus" min=0 :rules="[rules.schemeBoundry]"
                                    label="Project Based Bonus" type="number" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.productivityBasedBonus" min=0 :rules="[rules.schemeBoundry]"
                                    label="Productivity Based Bonus" type="number" :required="form.hasCommissionPolicy"
                                    :disabled="!form.hasCommissionPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateCommissionPolicy"
                        :disabled="!form.hasCommissionPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Employee Saving Plan (Pansion) Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Employee Saving Plan (Pansion) Policy"
                id="EmployeeSavingPlan">
                <v-form ref="PensionPolicyForm" v-model="validPensionPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasPansionPolicy" label="Has Pension Policy" color="success"
                                    @change="hasPansionPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.definedPensionScheme" :rules="[rules.required]"
                                    label="Defined Scheme" type="number" :required="form.hasPansionPolicy"
                                    :disabled="!form.hasPansionPolicy">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.pensionSchemeDetails" :items="pensionSchemeDetailsOptions"
                                    :rules="[rules.required]" label="Scheme Details" :required="form.hasPansionPolicy"
                                    :disabled="!form.hasPansionPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.vestingRolePercPerYear"
                                    :rules="[rules.required, rules.vestingRolePercPerYear]" label="Defined Scheme"
                                    type="number" :required="form.hasPansionPolicy" :disabled="!form.hasPansionPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.loanAgainstPension" :items="YesNoOptions" :rules="[rules.required]"
                                    label="Loan Against pension" :required="form.hasPansionPolicy"
                                    :disabled="!form.hasPansionPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.loanAgainstPensionDetails" :rules="[rules.required]"
                                    label="Loan Against Pension Details" type="text"
                                    :required="form.loanAgainstPension != 'No'" :disabled="form.loanAgainstPension == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.stocOptionProgramExist" :items="YesNoOptions"
                                    :rules="[rules.required]" label="Employee Stock Option (ESOP):"
                                    :required="form.hasPansionPolicy" :disabled="!form.hasPansionPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field v-model="form.stocOptionProgram"
                                    :rules="[rules.required, rules.stocOptionProgram_PV]" min=0
                                    label="Employee Stock Option program value" type="number"
                                    :required="form.stocOptionProgramExist != 'No'"
                                    :disabled="form.stocOptionProgramExist == 'No'">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.stocOptionProgram_PV" row
                                    :required="form.stocOptionProgramExist != 'No'"
                                    :disabled="form.stocOptionProgramExist == 'No'">
                                    <v-radio label="Percent" :value="Number(0)"></v-radio>
                                    <v-radio label="Value" :value="Number(1)"></v-radio>

                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.selectedEmpStockOption" :items="EmpStockOptions"
                                    :rules="[rules.required]" label="Employee Stock Option Program option (ESOP)"
                                    :required="form.stocOptionProgramExist != 'No'"
                                    :disabled="form.stocOptionProgramExist == 'No'">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidatePensionPolicy"
                        :disabled="!form.hasPansionPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Overtime Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Overtime Policy" id="OvertimePolicy">
                <v-form ref="OvertimePolicyForm" v-model="validOverTimePolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasOvertimePolicy" label="Has Overtime Policy" color="success"
                                    @change="hasOvertimePolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.otTargetGroup" :items="otTargetGroupOptions"
                                    :rules="[rules.required]" label="Policy Target Group" :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field v-model="form.dayTimeRate" :rules="[rules.required, rules.dayTimeRate_PV]"
                                    label="DayTime Rate" min=0 type="number" :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.dayTimeRate_PV" row :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                    <v-radio label="Percent" :value="Number(0)"></v-radio>
                                    <v-radio label="Value" :value="Number(1)"></v-radio>

                                </v-radio-group>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field v-model="form.nightTimeRate" :rules="[rules.required, rules.nightTimeRate_PV]"
                                    label="NightTime Rate" min=0 type="number" :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.nightTimeRate_PV" row :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                    <v-radio label="Percent" :value="Number(0)"></v-radio>
                                    <v-radio label="Value" :value="Number(1)"></v-radio>

                                </v-radio-group>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field v-model="form.holidayRate" :rules="[rules.required, rules.holidayRate_PV]"
                                    label="Holiday Rate" min=0 type="number" :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.holidayRate_PV" row :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                    <v-radio label="Percent" :value="Number(0)"></v-radio>
                                    <v-radio label="Value" :value="Number(1)"></v-radio>

                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.capOnOTExists" :items="YesNoOptions" :rules="[rules.required]"
                                    label="Cap on overtime Exists" :required="form.hasOvertimePolicy"
                                    :disabled="!form.hasOvertimePolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.capOnOTHours" :rules="[rules.required]"
                                    label="Cap On Overtime- Hours" min=0 type="number" :required="form.capOnOTExists != 'No'"
                                    :disabled="form.capOnOTExists == 'No'">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.capOnOTHoursPerMonth" :rules="[rules.required]"
                                    label="Cap On Overtime Hours per Month (If Applicable)" min=0 type="number"
                                    :required="form.capOnOTExists != 'No'" :disabled="form.capOnOTExists == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateOvertimePolicy"
                        :disabled="!form.hasOvertimePolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Work Conditions').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Work Conditions" id="WorkConditions">
                <v-form ref="WorkRemotePolicyForm" v-model="validWorkRemotePolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.isWorkRemotely" label="Has Work Remote" color="success"
                                    @change="isWorkRemotelyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-select v-model="form.workRemoteDescription" :items="workRemoteDescriptionOptions"
                                    :rules="[rules.required]" label="Work Remote Description"
                                    :required="form.isWorkRemotely" :disabled="!form.isWorkRemotely">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.workRemoteDaysAllowed"
                                    :rules="[rules.required, rules.workRemoteDaysAllowed]" label="Work Remote Days Allowed"
                                    min=0 type="number" :required="form.isWorkRemotely" :disabled="!form.isWorkRemotely">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.isWorkHoursFlexibility" :items="YesNoOptions"
                                    :rules="[rules.required]" label="IsFlexible Working Hours"
                                    :required="form.isWorkRemotely" :disabled="!form.isWorkRemotely">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.flexibleHoursAllowed"
                                    :rules="[rules.required, rules.flexibleHoursAllowed]" min=0
                                    label="Flexible Hours Allowed" type="number"
                                    :required="form.isWorkHoursFlexibility != 'No'"
                                    :disabled="form.isWorkHoursFlexibility == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.isUnConditionalLeaveOption" :items="YesNoOptions"
                                    :rules="[rules.required]" label="Cap on overtime Exists" :required="form.isWorkRemotely"
                                    :disabled="!form.isWorkRemotely">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.unconditionalLeaveOtions" :items="UnconditionalLeaveOtionsList"
                                    :rules="[rules.required]" label="Has UnConditional Leave"
                                    :required="form.isUnConditionalLeaveOption != 'No'"
                                    :disabled="form.isUnConditionalLeaveOption == 'No'">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateWorkRemotePolicy"
                        :disabled="!form.isWorkRemotely">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Benefits Data').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Benefits Data">
                <v-form ref="MedicalPolicyForm" v-model="validMedicalPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6" id="MedicalPolicy">
                                <v-checkbox v-model="form.iskMedicalPolicyExist" label="Medical Policy" color="success"
                                    hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.medicalCoverageScope" :items="medicalCoverageScopeOptions"
                                    :rules="[rules.required]" label="Coverage Scope Type"
                                    :required="form.iskMedicalPolicyExist" :disabled="!form.iskMedicalPolicyExist">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.medicalNumOfDependants" :rules="[rules.required]"
                                    label="Medical No. Of Dependants" min=0 type="number"
                                    :required="form.iskMedicalPolicyExist" :disabled="!form.iskMedicalPolicyExist">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.medicalCoverageType" :items="medicalCoverageTypeOptions"
                                    :rules="[rules.required]" label="Medical Coverage Type"
                                    :required="form.iskMedicalPolicyExist" :disabled="!form.iskMedicalPolicyExist">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.medicalSchemeType" :items="medicalSchemeTypeOptions"
                                    :rules="[rules.required]" label="Medical Scheme Type"
                                    :required="form.iskMedicalPolicyExist" :disabled="!form.iskMedicalPolicyExist">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field v-model="medicalCoverageCategory" label="Coverage Categories"
                                    :required="form.iskMedicalPolicyExist" :disabled="!form.iskMedicalPolicyExist">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="green darken-1" :disabled="!form.iskMedicalPolicyExist"
                                    @click="addMedicalCoverageCategory">Add</v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-card :disabled="!form.iskMedicalPolicyExist">
                                    <v-toolbar flat color="green" dark>
                                        <v-toolbar-title>Medical Categories</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-item-group active-class="primary">
                                            <v-container v-if="ddlmedicalCoverageCategories.length == 0">
                                                <v-row>
                                                    <v-col>
                                                        No categories added
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-container v-else>
                                                <v-row v-for="item in ddlmedicalCoverageCategories" :key="item">
                                                    <v-col sm="2" md="6">
                                                        {{ item }}
                                                    </v-col>
                                                    <v-col sm="6" md="6" align="top">
                                                        <v-btn color="green darken-1" text
                                                            @click="removeFromMedicalCategories(item)">x</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-item-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.medicalServiceProvider" :items="medicalServiceProviderOptions"
                                    :rules="[rules.required]" label="Medical Service Provider"
                                    :required="form.iskMedicalPolicyExist" :disabled="!form.iskMedicalPolicyExist">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.srviceProviderCompanyId" :items="srviceProviderCompanies"
                                    :rules="[rules.required]" item-text="name" item-value="id" label="Medical Company Name"
                                    :required="form.medicalServiceProvider == 1"
                                    :disabled="form.medicalServiceProvider == 2">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="green darken-1" :disabled="!form.iskMedicalPolicyExist"
                                    @click="showTitleLevelMedicalPolicyDialog">Add Title Level Medical Policy</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6" id="LifeInsurancePolicy">
                                <v-checkbox v-model="form.isLifeInsurancePolicy" label="Life Insurance Policy"
                                    color="success" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.lifeInTypeOfCoverage" :items="lifeInTypeOfCoverageOptions"
                                    :rules="[rules.required]" label="LIfe Insurance Coverage Type"
                                    :required="form.isLifeInsurancePolicy" :disabled="!form.isLifeInsurancePolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.valueOfLifeInCoverage" :rules="[rules.required]"
                                    label="Life Insurance Coverage Value" min=0 type="number"
                                    :required="form.isLifeInsurancePolicy" :disabled="!form.isLifeInsurancePolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.lifeInServiceProvider" :items="healthInServiceProviderOptions"
                                    :rules="[rules.required]" label="Life Insurance Service Provider"
                                    :required="form.isLifeInsurancePolicy" :disabled="!form.isLifeInsurancePolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.lifeInServiceCompany" :items="lifeInServiceCompanies"
                                    :rules="[rules.required]" item-text="name" item-value="id"
                                    label="Insurance Company Name" :required="form.isLifeInsurancePolicy"
                                    :disabled="!form.isLifeInsurancePolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="green darken-1" :disabled="!form.isLifeInsurancePolicy"
                                    @click="showTitleLevelInsurancePolicyDialog">Add Title Level Insurance Policy</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateMedicalPolicy"
                        :disabled="!form.iskMedicalPolicyExist || !form.isLifeInsurancePolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Physical Disability Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Physical Disability Policy"
                id="PhysicalDisabilityPolicy">
                <v-form ref="PhysicalDisabilityPolicyForm" v-model="validPhysicalDisabilityPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasPhysicalDisabilityPolicy" label="Physical Disability Policy"
                                    color="success" @change="hasPhysicalDisabilityPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.physicalDisTypeOfCoverage" :items="physicalDisTypeOfCoverageOptions"
                                    :rules="[rules.required]" label="Physical Disability Coverage Type"
                                    :required="form.hasPhysicalDisabilityPolicy"
                                    :disabled="!form.hasPhysicalDisabilityPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.physicalDisServiceProvider" :items="healthInServiceProviderOptions"
                                    :rules="[rules.required]" label="Physical Disability Service Provider"
                                    :required="form.hasPhysicalDisabilityPolicy"
                                    :disabled="!form.hasPhysicalDisabilityPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidatePhysicalDisabilityPolicy"
                        :disabled="!form.hasPhysicalDisabilityPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Transportation Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Transportation Policy" id="TransportationPolicy">
                <v-form ref="TransportationPolicyForm" v-model="validTransportationPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasTransportationPolicy" label="Has Transportation Policy"
                                    color="success" @change="hasTransportationPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-radio-group v-model="form.transportationModel" row :rules="[rules.required]"
                                    :required="form.hasTransportationPolicy" :disabled="!form.hasTransportationPolicy">
                                    <v-radio label="Provided by Company" :value="Number(1)"></v-radio>
                                    <v-radio label="Allowance" :value="Number(2)"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.transportationBusModel" row :rules="[rules.required]"
                                    :required="form.hasTransportationPolicy" :disabled="!form.hasTransportationPolicy">
                                    <v-radio label="Company Busses" :value="Number(1)"></v-radio>
                                    <v-radio label="Third Party" :value="Number(2)"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.transportationPickupDropoffModel" row :rules="[rules.required]"
                                    :required="form.hasTransportationPolicy" :disabled="!form.hasTransportationPolicy">
                                    <v-radio label="Door to Door" :value="Number(1)"></v-radio>
                                    <v-radio label="Meeting Points" :value="Number(2)"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.transportationMalesVsFemales" row :rules="[rules.required]"
                                    :required="form.hasTransportationPolicy" :disabled="!form.hasTransportationPolicy">
                                    <v-radio label="Males" :value="Number(1)"></v-radio>
                                    <v-radio label="Females" :value="Number(2)"></v-radio>
                                    <v-radio label="Both" :value="Number(3)"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.transportationSupportAmount" :rules="[rules.required]"
                                    label="Transportation Support Amount" min=0 type="number"
                                    :required="form.hasTransportationPolicy" :disabled="!form.hasTransportationPolicy">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="green darken-1" :disabled="!form.hasTransportationPolicy"
                                    @click="showTitleLevelTravelPolicyDialog">Add Title Level Transportation Policy</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateTransportationPolicy"
                        :disabled="!form.hasTransportationPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>

        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Car Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Car Policy" id="CarPolicy">
                <v-form ref="CarPolicyForm" v-model="validCarPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasCarPolicy" label="Has Car Policy" color="success"
                                    @change="hasCarPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.carPolicyPurpose" :items="carPolicyPurposeOptions"
                                    :rules="[rules.required]" label="Policy Purpose" :required="form.hasCarPolicy"
                                    :disabled="!form.hasCarPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.carPolicyFunding" :items="carPolicyFundingOPtions"
                                    :rules="[rules.required]" label="Car Fund Policy" :required="form.hasCarPolicy"
                                    :disabled="!form.hasCarPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.yearstoOwn" onkeydown="return event.keyCode !== 69"
                                    :rules="[rules.carfundPolicyRole, rules.caryearstoOwn]" label="Years to Own the Car"
                                    min=0 type="number" :required="form.isLifeInsurancePolicy != 'No'"
                                    :disabled="form.carPolicyFunding == null || form.carPolicyFunding == 1">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.PercentPaidByEmployee" :rules="[rules.carfundPolicyRole]"
                                    label=" % paid by the employee" min=0 type="number"
                                    :required="form.isLifeInsurancePolicy != 'No'"
                                    :disabled="form.carPolicyFunding == null || form.carPolicyFunding == 1">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.carSelctionBase" :items="carSelctionBaseOPtions"
                                    :rules="[rules.required]" label="Car Selection Base" :required="form.hasCarPolicy"
                                    :disabled="!form.hasCarPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.selectedCar" :items="carsList" :rules="[rules.required]"
                                    item-text="name" item-value="id" label="Car" :required="form.hasCarPolicy"
                                    :disabled="!form.hasCarPolicy">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.carInsuranceProvider" :items="carInsuranceProviders"
                                    :rules="[rules.required]" item-text="name" item-value="id"
                                    label="Car insurance Providers" :required="form.hasCarPolicy"
                                    :disabled="!form.hasCarPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="green darken-1" :disabled="!form.iskMedicalPolicyExist"
                                    @click="showTitleLevelCarPolicyDialog">Add Title Level Car Policy</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateCarPolicy"
                        :disabled="!form.hasCarPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Pay Advance Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Pay Advance Policy" id="PayAdvancePolicy">
                <v-form ref="PayAdvancePolicyForm" v-model="validPayAdvancePolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasPayAdvancePolicy" label="Has Pay Advance Policy"
                                    color="success" @change="hasPayAdvancePolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.salaryAdvancePercent" :rules="[rules.required]"
                                    label="Max % of the salary Advance" min=0 type="number"
                                    :required="form.hasPayAdvancePolicy" :disabled="!form.hasPayAdvancePolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidatePayAdvancePolicy"
                        :disabled="!form.hasPayAdvancePolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Payroll Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Payroll Payment Method" id="PayrollPolicy">
                <v-form ref="PayrollPolicyForm" v-model="validPayrollPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasPayrollPolicy" label="Payroll Payment Method" color="success"
                                    @change="PayrollPaymentMethodChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-radio-group v-model="form.PayrollOption_PV" :required="form.hasPayrollPolicy"
                                    :disabled="!form.hasPayrollPolicy">
                                    <v-radio label="Cash" :value="Number(0)"></v-radio>
                                    <v-radio label="Bank Account" :value="Number(1)"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.PayrolBank" :items="PayrolBanks" :rules="[rules.required]"
                                    item-text="name" item-value="id" label="Banks List"
                                    :required="form.PayrollOption_PV == Number(1)"
                                    :disabled="form.PayrollOption_PV == Number(0)">
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="validatePayrollOptions"
                        :disabled="!form.hasPayAdvancePolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Staff Loan Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Staff Loan Policy" id="StaffLoanPolicy">
                <v-form ref="StaffLoanPolicyForm" v-model="validStaffLoanPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasStaffLoanPolicy" label="Has Staff Loan Policy" color="success"
                                    @change="hasCarPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.salaryLoanPercent" :rules="[rules.required]"
                                    label="Max % of the salary Loan" min=0 type="number" :required="form.hasStaffLoanPolicy"
                                    :disabled="!form.hasStaffLoanPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateStaffLoanPolicy"
                        :disabled="!form.hasStaffLoanPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Connectivity Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Connectivity Policy" id="ConnectivityPolicy">
                <v-form ref="ConnectivityPolicyForm" v-model="validConnectivityPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.connectivityPolicy" :items="connectivityPolicyOptions"
                                    :rules="[rules.required]" label="Connectivity Policy Optios">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-btn color="green darken-1" :disabled="!form.iskMedicalPolicyExist"
                                    @click="showTitleLevelConnectivityPolicyDialog">Add Title Level Connectivity
                                    Policy</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateConnectivityPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Schooling Fees Support Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Schooling Fees Support" id="SchoolingFeesSupport">
                <v-form ref="SchoolingFeesSupportPolicyForm" v-model="validSchoolingFeesSupportPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasSchoolingFeesSupport" label="Has Schooling Fees Support"
                                    color="success" @change="hasSchoolingFeesSupportChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.schoolingSupportType" :items="schoolingSupportTypeOptions"
                                    :rules="[rules.required]" label="Schooling Support Type"
                                    :required="form.hasSchoolingFeesSupport" :disabled="!form.hasSchoolingFeesSupport">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.schoolingSupportValue" :rules="[rules.required]"
                                    label="Support Amount or Percent" min=0 type="number"
                                    :required="form.hasSchoolingFeesSupport" :disabled="!form.hasSchoolingFeesSupport">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.hasCapOPtion" :items="YesNoOptions" :rules="[rules.required]"
                                    label="Has Cap Option" :required="form.hasSchoolingFeesSupport"
                                    :disabled="!form.hasSchoolingFeesSupport">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.capValue" :rules="[rules.SchoolingCapOtion]" label="Cap Value"
                                    min=0 type="number" :required="form.hasCapOPtion != 'No'"
                                    :disabled="form.hasCapOPtion == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-col cols="12" sm="2" md="2">
                        <v-btn color="green darken-1" :disabled="!form.hasSchoolingFeesSupport"
                            @click="showTitleLevelSchoolingPolicyDialog">Add Title Level Schooling Policy</v-btn>
                    </v-col>

                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateSchoolingFeesSupportPolicy"
                        :disabled="!form.hasSchoolingFeesSupport">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Marriage Support Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Marriage Support Policy" id="MarriageSupportPolicy">
                <v-form ref="MarriageCashAwardForm" v-model="validMarriageCashAwardPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasMarriageCashAward" label="Marriage Cash Award" color="success"
                                    @change="hasMarriageCashAwardChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.marriageAwardType" :items="schoolingSupportTypeOptions"
                                    :rules="[rules.required]" label="Marriage Award Type"
                                    :required="form.hasMarriageCashAward" :disabled="!form.hasMarriageCashAward">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.marriageAwardValue" :rules="[rules.required]"
                                    label="Support Amount or Percent" min=0 type="number"
                                    :required="form.hasMarriageCashAward" :disabled="!form.hasMarriageCashAward">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.hasMarriageCapOPtion" :items="YesNoOptions"
                                    :rules="[rules.required]" label="Has Cap Option" :required="form.hasMarriageCashAward"
                                    :disabled="!form.hasMarriageCashAward">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.marriageCapValue" :rules="[rules.MarriageCapOtion]" min=0
                                    label="Cap Value" type="number" :required="form.hasMarriageCapOPtion != 'No'"
                                    :disabled="form.hasMarriageCapOPtion == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateMarriageCashAwardPolicy"
                        :disabled="!form.hasMarriageCashAward">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'New Born Cash Award Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="New Born Cash Award Policy" id="NewBornCashAwardPolicy">
                <v-form ref="NewBornCashAwardForm" v-model="validNewBornCashAwardPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasNewBornCashAward" label="Has New Born Cash Award Policy"
                                    color="success" @change="hasNewBornCashAwardChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.newBornAwardType" :items="schoolingSupportTypeOptions"
                                    :rules="[rules.required]" label="New Born Award Type"
                                    :required="form.hasNewBornCashAward" :disabled="!form.hasNewBornCashAward">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.newBornAwardValue" :rules="[rules.required]"
                                    label="Support Amount or Percent" min=0 type="number"
                                    :required="form.hasNewBornCashAward" :disabled="!form.hasNewBornCashAward">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.hasNewBornCapOPtion" :items="YesNoOptions" :rules="[rules.required]"
                                    label="Has Cap Option" :required="form.hasNewBornCashAward"
                                    :disabled="!form.hasNewBornCashAward">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.newBornCapValue" :rules="[rules.NewBornCapOtion]"
                                    label="Cap Value" min=0 type="number" :required="form.hasNewBornCapOPtion != 'No'"
                                    :disabled="form.hasNewBornCapOPtion == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateNewBornCashAwardPolicy"
                        :disabled="!form.hasNewBornCashAward">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Cash Support for 1st Degree relative Death Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Cash Support for 1st Degree relative's Death Policy"
                id="CashSupportfor1stDegreerelative">
                <v-form ref="CashSupportforFirstDegRelDeathPolicyForm" v-model="validCashSupportforFirstDegRelDeathPolicy"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasCashSupportforFirstDegRelDeath"
                                    label="Has Cash Support for 1st Degree relative's Death Policy" color="success"
                                    @change="hasCashSupportforFirstDegRelDeathChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.relativeDeathSupportType" :items="schoolingSupportTypeOptions"
                                    :rules="[rules.required]" label="Relative Death Support Type:"
                                    :required="form.hasCashSupportforFirstDegRelDeath"
                                    :disabled="!form.hasCashSupportforFirstDegRelDeath">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.relativeDeathSupportValue" :rules="[rules.required]"
                                    label="Support Amount or Percent" min=0 type="number"
                                    :required="form.hasCashSupportforFirstDegRelDeath"
                                    :disabled="!form.hasCashSupportforFirstDegRelDeath">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.hasRelativeDeathCapOPtion" :items="YesNoOptions"
                                    :rules="[rules.required]" label="Has Cap Option"
                                    :required="form.hasCashSupportforFirstDegRelDeath"
                                    :disabled="!form.hasCashSupportforFirstDegRelDeath">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.relativeDeathSupportCapValue"
                                    :rules="[rules.RelativeDeathCapOPtion]" min=0 label="Cap Value" type="number"
                                    :required="form.hasRelativeDeathCapOPtion != 'No'"
                                    :disabled="form.hasRelativeDeathCapOPtion == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateCashSupportforFirstDegRelDeathPolicy"
                        :disabled="!form.hasCashSupportforFirstDegRelDeath">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Haj and Umrah support Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Haj and Umrah support Policy" id="HajandUmrahsuppor">
                <v-form ref="HajAndUmrahSupportPolicyForm" v-model="validsHajAndUmrahSupportPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasHajAndUmrahSupport" label="Has Haji and Umrah Support Policy"
                                    color="success" @change="hasHajAndUmrahSupportChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.hajAndUmrahSupportType" :items="schoolingSupportTypeOptions"
                                    :rules="[rules.required]" label="Haji and Umrah Support Type:"
                                    :required="form.hasHajAndUmrahSupport" :disabled="!form.hasHajAndUmrahSupport">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.hajAndUmrahSupportValue" :rules="[rules.required]"
                                    label="Support Amount or Percent" min=0 type="number"
                                    :required="form.hasHajAndUmrahSupport" :disabled="!form.hasHajAndUmrahSupport">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.hashajAndUmrahCapOPtion" :items="YesNoOptions"
                                    :rules="[rules.required]" label="Has Cap Option" :required="form.hasHajAndUmrahSupport"
                                    :disabled="!form.hasHajAndUmrahSupport">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.hajAndUmrahCapValue" :rules="[rules.hajAndUmrahCapOPtion]" min=0
                                    label="Cap Value" type="number" :required="form.hashajAndUmrahCapOPtion != 'No'"
                                    :disabled="form.hashajAndUmrahCapOPtion == 'No'">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateHajAndUmrahSupportPolicy"
                        :disabled="!form.hasHajAndUmrahSupport">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Employee Monetary Award Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Employee Monetary Award Policy" id="EmployeeMonettary">
                <v-form ref="MonetaryAwardPForm" v-model="validMonetaryAwardPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasMonetaryAwardPolicy" label="Has Employee Monetary Award Policy"
                                    color="success" @change="hasMonetaryAwardPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-select v-model="form.monetaryAwardType" :items="schoolingSupportTypeOptions"
                                    :rules="[rules.required]" label="Award Type" :required="form.hasMonetaryAwardPolicy"
                                    :disabled="!form.hasMonetaryAwardPolicy">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.monetaryAwardValue" :rules="[rules.required]"
                                    label="Award Amount or Percent" min=0 type="number"
                                    :required="form.hasMonetaryAwardPolicy" :disabled="!form.hasMonetaryAwardPolicy">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-col cols="12" sm="2" md="2">
                        <v-btn color="green darken-1" :disabled="!form.hasMonetaryAwardPolicy"
                            @click="showTitleLevelMonetaryAwardPolicyDialog">Add Title Level Monetary Policy</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateMonetaryAwardPolicy"
                        :disabled="!form.hasMonetaryAwardPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-0"
            v-if="visiblePolicies.filter((o) => o == 'Employee Non-Monetary Award Policy').length > 0">
            <v-card class="px-5 py-10" icon="clipboard-text" title="Employee Non-Monetary Award Policy"
                id="EmployeeNonMonetary">
                <v-form ref="NonMonetaryAwardPolicyForm" v-model="validNonMonetaryAwardPolicy" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-model="form.hasNonMonetaryAwardPolicy"
                                    label="Has Employee Non-Monetary Award Policy" color="success"
                                    @change="hasNonMonetaryAwardPolicyChanged" hide-details>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-checkbox v-for="item in nonMonetaryAwardTypeOptions" :key="item.value"
                                    :value="item.value" v-model="form.nonMonetaryAwardType" :label=item.text color="success"
                                    multiple hide-details :required="form.hasNonMonetaryAwardPolicy"
                                    :disabled="!form.hasNonMonetaryAwardPolicy">
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field v-model="form.otherNonMonetaryAward" :rules="[rules.nonMonetaryOtherOption]"
                                    label="Other Type" type="text"
                                    :required="form.nonMonetaryAwardType.length == 0 || form.nonMonetaryAwardType.indexOf(4) == -1"
                                    :disabled="form.nonMonetaryAwardType.length == 0 || form.nonMonetaryAwardType.indexOf(4) == -1">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="ValidateNonMonetaryAwardPolicy"
                        :disabled="!form.hasNonMonetaryAwardPolicy">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
        <v-dialog v-model="titleLevelMedicalPolicyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Medical Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleMedicalPolicy" v-model="validTitleMedicalPolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt" :rules="[rules.required]" label="Coverage Amount"
                                        min=1 type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-btn color="green darken-1" @click="validateTitleMedicalValues">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleMedicalFields" :items="titleMedicalPolicyList"
                                            ref="medicalTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.jobName`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="approveDeleteConfirmTitleMedicalPolicy(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="titleLevelTravelPolicyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Transportation Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleTravelPolicy" v-model="validTitleTravelPolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt" :rules="[rules.required, rules.titleTravelPercent]"
                                        label="Coverage Amount" min=1 type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-radio-group v-model="titleTravelPercentAmount" row :rules="[rules.required]">
                                        <v-radio label="Percent" :value="Number(1)"></v-radio>
                                        <v-radio label="Value" :value="Number(2)"></v-radio>

                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10" sm="10" md="10">
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-btn color="green darken-1" @click="validateTitleTravelValues">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleTravelFields" :items="titleTravelPolicyList"
                                            ref="medicalTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.jobName`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.AmountOrPercent`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.AmountOrPercent`]="{ item }">
                                                {{ getVal(item.AmountOrPercent) }}
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="approveDeleteConfirmTitleTravelPolicy(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="titleLevelMonetaryAwardPolicyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Monetary Award Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleMonetaryAwardPolicy" v-model="validTitleMonetaryAwardPolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt"
                                        :rules="[rules.required, rules.titleMonetaryAwardPercent]" label="Coverage Amount"
                                        min=1 type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-radio-group v-model="titleMonetaryAwardAmount" row :rules="[rules.required]">
                                        <v-radio label="Percent" :value="Number(1)"></v-radio>
                                        <v-radio label="Value" :value="Number(2)"></v-radio>

                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10" sm="10" md="10">
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-btn color="green darken-1" @click="validateTitleMonetaryAwardValues">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleTravelFields" :items="titleMonetaryAwardPolicyList"
                                            ref="medicalTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.jobName`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.AmountOrPercent`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.AmountOrPercent`]="{ item }">
                                                {{ getVal(item.AmountOrPercent) }}
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="approveDeleteConfirmTitleMonetaryAwardPolicy(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>



        <v-dialog v-model="titleLevelSchoolingPolicyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Schooling Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleSchoolingPolicy" v-model="validTitleSchoolingPolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt"
                                        :rules="[rules.required, rules.titleSchoolingPercent]" label="Coverage Amount" min=1
                                        type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-radio-group v-model="titleSchoolingAmount" row :rules="[rules.required]">
                                        <v-radio label="Percent" :value="Number(1)"></v-radio>
                                        <v-radio label="Value" :value="Number(2)"></v-radio>

                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="titleSchoolingHasCap" :items="YesNoOptions" :rules="[rules.required]"
                                        @change="titleSchoolcapvalueCHanged" label="Has Cap Option">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="titleSchoolingCapValue" :rules="[rules.required]"
                                        label="Cap Value" min=1 type="number" :required="titleSchoolingHasCap != 'No'"
                                        :disabled="titleSchoolingHasCap == 'No'">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10" sm="10" md="10">
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-btn color="green darken-1" @click="validateTitleSchoolingValues">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleSchoolingFields" :items="titleSchoolingPolicyList"
                                            ref="medicalTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.jobName`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.AmountOrPercent`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.hasCapValue`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.titleCapValue`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.AmountOrPercent`]="{ item }">
                                                {{ getVal(item.AmountOrPercent) }}
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="approveDeleteConfirmTitleSchoolingPolicy(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="titleLevelInsurancePolicyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Insurance Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleInsurancePolicy" v-model="validTitleInsurancePolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt" :rules="[rules.required]" label="Coverage Amount"
                                        min=1 type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-btn color="green darken-1" @click="validateTitleInsuranceValues">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleMedicalFields" :items="titleInsurancePolicyList"
                                            ref="insuranceTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.jobName`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="approveDeleteConfirmTitleInsurancePolicy(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>








        <v-dialog v-model="titleLevelConnectivityPolicyDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Connectivity Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleConnectivityPolicy" v-model="validTitleConnectivityPolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-btn color="green darken-1" @click="showTitleConnectivityDetails">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleConnectivityFields"
                                            :items="titleConnectivityPolicyList" ref="titleConnectivityPolicyList"
                                            class="elevation-1">
                                            <template v-slot:[`header.job`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Details`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.Details`]="{ item }">
                                                <v-icon small class="mr-2" @click="editTitleConnectivityDetails(item)"
                                                    color="green">
                                                    mdi-pencil-outline
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="titleconnectivityDetailsDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Title Connectivity Details </span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleConnectivityDetailsForm" v-model="validTitleConnectivityDetailsForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedDevice" :items="devices" item-text="name" item-value="id"
                                        :rules="[rules.required]" label="Select Item">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt" :rules="[rules.required]" label="Coverage Amount"
                                        min=1 type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-btn color="green darken-1" @click="validateTitleConnectivityDetailsValue">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="titleDevicesFields" :items="titleDevicesList"
                                            ref="insuranceTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.device`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="DeleteConfirmTitleConnectivityDetails(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edittitleconnectivityDetailsDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Edit Title Connectivity Details </span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleConnectivityDetailsForm" v-model="validTitleConnectivityDetailsForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedDevice" :items="devices" item-text="name" item-value="id"
                                        :rules="[rules.required]" label="Select Item">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt" :rules="[rules.required]" label="Coverage Amount"
                                        min=1 type="number">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-btn color="green darken-1"
                                        @click="validateTitleConnectivityDetailsValue(true)">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="titleDevicesFields" :items="titleDevicesList"
                                            ref="insuranceTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.device`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.CoverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2"
                                                    @click="DeleteConfirmTitleConnectivityDetails(item)" color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="DeleteConfirmTitleConnectivityDetailsDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleConnectivityDevice">Yes</v-btn>
                    <v-btn color="green darken-1" text
                        @click="DeleteConfirmTitleConnectivityDetailsDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>




        <v-dialog v-model="titleLevelCarPolicyDialog" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Title-Level Car Policy</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="titleCarPolicy" v-model="validTitleCarPolicyForm">
                        <v-container>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-select v-model="selectedUserJob" :items="userJobs" item-text="job" item-value="jobId"
                                        :rules="[rules.required]" label="Select Job">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-radio-group v-model="titleCarCoverageBase" row :rules="[rules.required]">
                                        <v-radio label="Value" :value="Number(1)"></v-radio>
                                        <v-radio label="Brand" :value="Number(2)"></v-radio>

                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="coverageAmt" :rules="[rules.titleCarValueCoverage]"
                                        :disabled="titleCarCoverageBase == 2" label="Coverage Amount" min=1 type="number">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-select v-model="CarBrand" :items="carsList" :rules="[rules.titleCarModelCoverage]"
                                        item-text="name" item-value="id" label="Car" :disabled="titleCarCoverageBase == 1">
                                    </v-select>
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-text-field v-model="Carmodel" :rules="[rules.titleCarModelCoverage]"
                                        :disabled="titleCarCoverageBase == 1" label="Model">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="10" sm="10" md="10">
                                </v-col>
                                <v-col cols="2" sm="2" md="2">
                                    <v-btn color="green darken-1" @click="validateTitleCarValues">Add</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-container color="primary">
                                    <v-card icon="clipboard-text" title="Titles" class="elevation-1">
                                        <v-data-table :headers="TitleCarFields" :items="titleTravelPolicyList"
                                            ref="medicalTitleEntries" class="elevation-1">
                                            <template v-slot:[`header.jobName`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.coverageBase`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.coverageAmount`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.brand`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.brandModel`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`header.Action`]="{ header }">
                                                <h6><b> {{ header.text }}</b> </h6>
                                            </template>
                                            <template v-slot:[`item.coverageBase`]="{ item }">
                                                {{ getConverageBase(item.coverageBase) }}
                                            </template>
                                            <template v-slot:[`item.Action`]="{ item }">
                                                <v-icon small class="mr-2" @click="approveDeleteConfirmTitleCarPolicy(item)"
                                                    color="red">
                                                    mdi-delete-empty
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-container>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>



        <div class="text-center">
            <v-snackbar v-model="snackbar" timeout="3000">
                {{ alertMsg }}
                <template v-slot:action="{ attrs }">
                    <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>

        <v-dialog v-model="DeleteConfirmTitleMedicalPolicyDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleMedicalPolicy">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="DeleteConfirmTitleMedicalPolicyDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog v-model="DeleteConfirmTitleCarPolicyDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleCarPolicy">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="DeleteConfirmTitleCarPolicyDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="DeleteConfirmTitleInsurancePolicyDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleInsurancePolicy">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="DeleteConfirmTitleInsurancePolicyDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="DeleteConfirmTitleTravelPolicyDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleTravelPolicy">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="DeleteConfirmTitleTravelPolicyDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="DeleteConfirmTitleSchoolingPolicyDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleSchoolingPolicy">Yes</v-btn>
                    <v-btn color="green darken-1" text @click="DeleteConfirmTitleSchoolingPolicyDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="DeleteConfirmTitleMonetaryAwardPolicyDialog" max-width="500px">
            <v-card :loading=loading loading-text="Loading... Please wait">
                <v-card-title class="text-h3">Are you sure ? </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteTitleMonetaryAwardPolicy">Yes</v-btn>
                    <v-btn color="green darken-1" text
                        @click="DeleteConfirmTitleMonetaryAwardPolicyDialog = false">No</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>




    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
export default {
    mixins: [validationMixin],
    computed: {
        customerNumber() {
            if (this.$store.state.userDetails.subscriptionId != -1) {
                return this.$store.state.userDetails.subscriptionId;
            }
            else {
                return this.currentSelectedCustomer
            }
        },
        icon() {
            if (this.likeAll) return 'mdi-close-box'
            if (this.likesSome) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        likesAll() {
            return this.visiblePolicies.length === this.policiesEntries.length
        },
        likesSome() {
            return this.visiblePolicies.length > 0 && !this.policiesEntries
        },
    },
    data() {
        return {
            edittitleconnectivityDetailsDialog: false,
            DeleteConfirmTitleConnectivityDetailsDialog: false,
            titleDevicesList: [],
            selectedDevice: null,
            validTitleConnectivityDetailsForm: false,
            titleconnectivityDetailsDialog: false,
            validTitleConnectivityPolicyForm: false,
            titleLevelConnectivityPolicyDialog: false,
            titleConnectivityPolicyList: [],
            devices: [],
            DeleteConfirmTitleSchoolingPolicyDialog: false,
            titleSchoolingCapValue: null,
            titleSchoolingAmount: null,
            titleSchoolingHasCap: null,
            validTitleSchoolingPolicyForm: false,
            titleLevelSchoolingPolicyDialog: false,
            titleMonetaryAwardAmount: null,
            validTitleMonetaryAwardPolicyForm: false,
            titleLevelMonetaryAwardPolicyDialog: false,
            titleMonetaryAwardPolicyList: [],
            DeleteConfirmTitleCarPolicyDialog: false,
            titleCarPolicyList: [],
            titleCarCoverageBase: 1,
            CarBrand: null,
            Carmodel: null,
            validTitleCarPolicyForm: false,
            titleLevelCarPolicyDialog: false,
            DeleteConfirmTitleTravelPolicyDialog: false,
            titleTravelPolicyList: [],
            titleTravelPercentAmount: null,
            validTitleTravelPolicyForm: false,
            titleLevelTravelPolicyDialog: false,
            DeleteConfirmTitleInsurancePolicyDialog: false,
            validTitleInsurancePolicyForm: false,
            titleLevelInsurancePolicyDialog: false,
            titleInsurancePolicyList: [],
            DeleteConfirmTitleMedicalPolicyDialog: false,
            alertMsg: null,
            snackbar: false,
            coverageAmt: null,
            titleMedicalPolicyList: [],
            selectedUserJob: null,
            loading: false,
            userJobs: [],
            visiblePolicies: [],
            policiesEntries: [
                'Sales Commission Policy',
                'Employee Saving Plan (Pansion) Policy',
                'Overtime Policy',
                'Work Conditions',
                'Benefits Data',
                'Physical Disability Policy',
                'Transportation Policy',
                'Car Policy',
                'Pay Advance Policy',
                'Payroll Policy',
                'Staff Loan Policy',
                'Connectivity Policy',
                'Schooling Fees Support Policy',
                'Marriage Support Policy',
                'New Born Cash Award Policy',
                'Cash Support for 1st Degree relative Death Policy',
                'Haj and Umrah support Policy',
                'Employee Monetary Award Policy',
                'Employee Non-Monetary Award Policy',

            ],
            titleSchoolingPolicyList: [],
            DeleteConfirmTitleMonetaryAwardPolicyDialog: false,
            editedItem: null,
            editedIndex: null,
            titleLevelMedicalPolicyDialog: false,
            PayrolBanks: [],
            ddlmedicalCoverageCategories: [],
            validTitleMedicalPolicyForm: false,
            validPayrollPolicy: false,
            validCommissionPolicy: false,
            validPensionPolicy: false,
            validOverTimePolicy: false,
            validWorkRemotePolicy: false,
            validMedicalPolicy: false,
            validPhysicalDisabilityPolicy: false,
            validTransportationPolicy: false,
            validCarPolicy: false,
            validPayAdvancePolicy: false,
            validStaffLoanPolicy: false,
            validConnectivityPolicy: false,
            validSchoolingFeesSupportPolicy: false,
            validMarriageCashAwardPolicy: false,
            validNewBornCashAwardPolicy: false,
            validCashSupportforFirstDegRelDeathPolicy: false,
            validsHajAndUmrahSupportPolicy: false,
            validMonetaryAwardPolicy: false,
            validNonMonetaryAwardPolicy: false,
            carInsuranceProviders: [],
            carsList: [],
            lifeInServiceCompanies: [],
            srviceProviderCompanies: [],
            rules: {
                required: value => !!value || 'Required Field',
                otherFreqOfPayment: value => (!(this.form.freqOfPayment == 5 && value == null)) || 'required',
                vestingRolePercPerYear: value => (value >= 0 && value <= 20) || 'range between 0% - 20%',
                schemeBoundry: value => (Number(value) >= 0 && Number(value) <= 100) || 'range between 0% - 100%',
                schemeBoundryMin: value => (Number(value) <= Number(this.form.schemeBoundryMax)) || 'Min cannot be greater than max',
                workRemoteDaysAllowed: value => (Number(value) >= 0 && Number(value) <= 365) || 'range between 1 - 365',
                flexibleHoursAllowed: value => (Number(value) >= 0 && Number(value) <= 525600) || 'range between 1 - 525600',
                carfundPolicyRole: value => (!(this.form.carPolicyFunding != 1 && value == null)) || 'required',
                caryearstoOwn: value => (value >= 0 && value <= 20) || 'range between 0 - 20',

                SchoolingCapOtion: value => (!(this.form.hasCapOPtion != 'No' && value == null)) || 'required',
                MarriageCapOtion: value => (!(this.form.hasMarriageCapOPtion != 'No' && value == null)) || 'required',
                NewBornCapOtion: value => (!(this.form.hasNewBornCapOPtion != 'No' && value == null)) || 'required',
                RelativeDeathCapOPtion: value => (!(this.form.hasRelativeDeathCapOPtion != 'No' && value == null)) || 'required',
                hajAndUmrahCapOPtion: value => (!(this.form.hashajAndUmrahCapOPtion != 'No' && value == null)) || 'required',
                nonMonetaryOtherOption: value => (!(this.form.nonMonetaryAwardType.length > 0 && this.form.nonMonetaryAwardType.indexOf(4) >= 0 && value == null)) || 'required',
                stocOptionProgram_PV: value => (!(this.form.stocOptionProgram_PV == 0 && value > 100)) || 'value cannot exceed 100%',
                titleTravelPercent: value => (!(this.titleTravelPercentAmount == 1 && value > 100)) || 'value cannot exceed 100%',
                titleMonetaryAwardPercent: value => (!(this.titleMonetaryAwardAmount == 1 && value > 100)) || 'value cannot exceed 100%',
                titleSchoolingPercent: value => (!(this.titleSchoolingAmount == 1 && value > 100)) || 'value cannot exceed 100%',
                dayTimeRate_PV: value => (!(this.form.dayTimeRate_PV == 0 && value > 100)) || 'value cannot exceed 100%',
                nightTimeRate_PV: value => (!(this.form.nightTimeRate_PV == 0 && value > 100)) || 'value cannot exceed 100%',
                holidayRate_PV: value => (!(this.form.holidayRate_PV == 0 && value > 100)) || 'value cannot exceed 100%',
                titleCarValueCoverage: value => (this.titleCarCoverageBase == 1 && value == null) || 'required field',
                titleCarModelCoverage: value => (this.titleCarCoverageBase == 2 && value == null) || 'required field'
            },
            currentSelectedCustomer: null,
            CommissionBasisOptions: [
                { value: 1, text: 'Salary Based' },
                { value: 2, text: 'Volume Based' },
                { value: 3, text: 'Revenue Based' }],
            commissionModelOptions: [
                { value: 1, text: 'Fixed Amount Per Sale' },
                { value: 2, text: 'Calculation Basis Percent' }],
            freqOfPaymentOptions: [
                { value: 1, text: 'Monthly' },
                { value: 2, text: 'Quarterly' },
                { value: 3, text: 'Semi-Annual' },
                { value: 4, text: 'Annual' },
                { value: 5, text: 'Other' }],
            schemeModelOptions: [
                { value: 1, text: 'Progressive' },
                { value: 2, text: 'Regressive' },
                { value: 3, text: 'Flat' }],
            pensionSchemeDetailsOptions: [
                { value: 1, text: '100/0' },
                { value: 2, text: '80/20' },
                { value: 3, text: '60/40' },
                { value: 4, text: '50/50' },
            ],
            EmpStockOptions: [{ value: 1, text: 'Incentive' }, { value: 2, text: 'Non-QUalified (NSOs)' }],
            otTargetGroupOptions: [
                { value: 1, text: 'White Collar' },
                { value: 2, text: 'Blue Collar' },
                { value: 3, text: 'Both' }],

            workRemoteDescriptionOptions: [
                { value: 1, text: 'work from home' },
                { value: 2, text: 'work out of office' },
                { value: 3, text: 'Both' }],


            YesNoOptions: ["Yes", "No"],
            UnconditionalLeaveOtionsList: [
                { value: 1, text: 'Marriage Leave' },
                { value: 2, text: 'Education Leave' },
                { value: 3, text: 'Parental Care Leave' },
                { value: 4, text: 'Bereavement Leave' },
                { value: 5, text: 'Extended Medical Leave' },
                { value: 6, text: 'Other' }],
            medicalCoverageScopeOptions: [{ value: 1, text: 'Individual' }, { value: 2, text: 'Family' }],
            medicalCoverageTypeOptions: [{ value: 1, text: 'Contributory' }, { value: 2, text: 'Subsidized' }],
            medicalSchemeTypeOptions: [{ value: 1, text: 'Captive Insurance' }, { value: 2, text: 'Self Admin Insurance' }],
            medicalServiceProviderOptions: [{ value: 1, text: 'Insurance Company' }, { value: 2, text: 'Third Party Admin' }],
            lifeInTypeOfCoverageOptions: [{ value: 1, text: 'Normal Death' },
            { value: 2, text: 'Accidental Death' },
            { value: 3, text: 'Both' }],

            healthInServiceProviderOptions: [{ value: 1, text: 'Insurance Company' }, { value: 2, text: 'Third Party Admin' }],
            medicalCoverageCategory: null,
            physicalDisTypeOfCoverageOptions: [{ value: 1, text: 'Permenant' }, { value: 2, text: 'Partial' }],
            carPolicyPurposeOptions: [{ value: 1, text: 'Essential User' }, { value: 2, text: 'Equity User' }, { value: 3, text: 'Project Based User' }],
            carPolicyFundingOPtions: [{ value: 1, text: 'Rent' }, { value: 2, text: 'Purchase' }, { value: 3, text: 'Purchase to Own' }],
            carSelctionBaseOPtions: [{ value: 1, text: 'Brand Based' }, { value: 2, text: 'Value Based' }],
            connectivityPolicyOptions: [{ value: 1, text: 'Yes' }, { value: 2, text: 'None' }],
            schoolingSupportTypeOptions: [{ value: 1, text: 'Amount' }, { value: 2, text: 'Percent' }],
            nonMonetaryAwardTypeOptions: [
                { value: 1, text: 'Voucher for purchase discount' },
                { value: 2, text: 'Trip for two' },
                { value: 3, text: 'Tickets for two' },
                { value: 4, text: 'Other (Please Specify)' }
            ],
            form: {
                id: null,
                hasCommissionPolicy: false,
                commissionBasis: null,
                commissionModel: null,
                freqOfPayment: null,
                otherFreqOfPayment: null,
                schemeModel: null,
                schemeBoundryMin: null,
                schemeBoundryMax: null,
                hasPansionPolicy: false,
                definedPensionScheme: null,
                pensionSchemeDetails: null,
                vestingRolePercPerYear: null,
                loanAgainstPension: 'No',
                loanAgainstPensionDetails: null,
                stocOptionProgramExist: 'No',
                stocOptionProgram: null,
                stocOptionProgram_PV: Number(0),
                selectedEmpStockOption: null,
                hasOvertimePolicy: false,
                otTargetGroup: null,
                dayTimeRate: null,
                nightTimeRate: null,
                holidayRate: null,
                capOnOTExists: 'No',
                capOnOTHours: null,
                capOnOTHoursPerMonth: null,
                isWorkRemotely: false,
                workRemoteDescription: null,
                workRemoteDaysAllowed: null,
                isWorkHoursFlexibility: 'No',
                flexibleHoursAllowed: null,
                isUnConditionalLeaveOption: 'No',
                unconditionalLeaveOtions: [],
                iskMedicalPolicyExist: false,
                medicalCoverageScope: null,
                medicalNumOfDependants: null,
                medicalCoverageType: null,
                medicalSchemeType: null,
                medicalCoverageCategories: [],
                medicalServiceProvider: null,
                medicalServiceProviderCompany: null,
                isLifeInsurancePolicy: false,
                lifeInTypeOfCoverage: null,
                valueOfLifeInCoverage: null,
                lifeInServiceProvider: null,
                hasPhysicalDisabilityPolicy: false,
                physicalDisTypeOfCoverage: null,
                physicalDisServiceProvider: null,
                hasTransportationPolicy: false,
                transportationSupportAmount: null,
                transportationModel: null,
                transportationBusModel: null,
                transportationPickupDropoffModel: null,
                transportationMalesVsFemales: null,
                hasCarPolicy: false,
                carPolicyPurpose: null,
                carPolicyFunding: null,
                yearstoOwn: null,
                PercentPaidByEmployee: null,
                carSelctionBase: null,
                hasPayAdvancePolicy: false,
                hasPayrollPolicy: false,
                salaryAdvancePercent: null,
                hasStaffLoanPolicy: false,
                salaryLoanPercent: null,
                connectivityPolicy: null,
                hasSchoolingFeesSupport: false,
                schoolingSupportType: null,
                schoolingSupportValue: null,
                hasCapOPtion: 'No',
                capValue: null,
                hasMarriageCashAward: false,
                marriageAwardType: null,
                marriageAwardValue: null,
                hasMarriageCapOPtion: 'No',
                marriageCapValue: null,
                hasNewBornCashAward: false,
                newBornAwardType: null,
                newBornAwardValue: null,
                hasNewBornCapOPtion: 'No',
                newBornCapValue: null,
                hasCashSupportforFirstDegRelDeath: false,
                relativeDeathSupportType: null,
                relativeDeathSupportValue: null,
                hasRelativeDeathCapOPtion: 'No',
                relativeDeathSupportCapValue: null,
                hasHajAndUmrahSupport: false,
                hajAndUmrahSupportType: null,
                hajAndUmrahSupportValue: null,
                hashajAndUmrahCapOPtion: 'No',
                hajAndUmrahCapValue: null,
                hasMonetaryAwardPolicy: false,
                monetaryAwardType: null,
                monetaryAwardValue: null,
                hasNonMonetaryAwardPolicy: false,
                nonMonetaryAwardType: [],
                otherNonMonetaryAward: null,
                dayTimeRate_PV: Number(0),
                nightTimeRate_PV: Number(0),
                holidayRate_PV: Number(0),
                PayrollOption_PV: Number(0),
                carInsuranceProvider: null,
                selectedCar: null,
                lifeInServiceCompany: null,
                annualSalesCommission: null,
                projectBasedBonus: null,
                productivityBasedBonus: null,

            },
            TitleMedicalFields: [
                { value: 'jobName', text: 'Job Name', width: '200' },
                { value: 'CoverageAmount', text: 'Coverage Amount', width: '200' },
                { value: 'Action', text: 'Action', width: '200' },
            ],
            titleDevicesFields: [
                { value: 'device', text: 'Device', width: '200' },
                { value: 'CoverageAmount', text: 'Coverage Amount', width: '200' },
                { value: 'Action', text: 'Action', width: '200' },
            ],
            TitleConnectivityFields: [
                { value: 'job', text: 'Job Name', width: '200' },
                { value: 'Details', text: 'Details', width: '200' },
            ],
            TitleTravelFields: [
                { value: 'jobName', text: 'Job Name', width: '200' },
                { value: 'CoverageAmount', text: 'Coverage Amount', width: '200' },
                { value: 'AmountOrPercent', text: 'Amount / Percent', width: '200' },
                { value: 'Action', text: 'Action', width: '200' },
            ],
            TitleSchoolingFields: [
                { value: 'jobName', text: 'Job Name', width: '200' },
                { value: 'CoverageAmount', text: 'Coverage Amount', width: '200' },
                { value: 'AmountOrPercent', text: 'Amount / Percent', width: '200' },
                { value: 'HasCapSupport', text: 'Has Cap Value', width: '200' },
                { value: 'CapValue', text: 'Cap Value', width: '200' },
                { value: 'Action', text: 'Action', width: '200' },
            ],
            TitleCarFields: [
                { value: 'jobName', text: 'Job Name', width: '200' },
                { value: 'coverageBase', text: 'Coverage Base', width: '200' },
                { value: 'coverageAmount', text: 'Coverage Amount', width: '200' },
                { value: 'brand', text: 'Car Brand', width: '200' },
                { value: 'brandModel', text: 'Brand Model', width: '200' },
                { value: 'Action', text: 'Action', width: '200' },

            ],
        }
    },

    methods: {
        //    validateTitleConnectivityDetailsValue(){
        //     if(this.$refs.titleConnectivityDetailsForm.validate()){

        //         const first = this.titleConnectivityPolicyList.find((obj) => { return obj.id === this.selectedUserJob;});
        //         if(first != null){
        //             this.alertMsg = "ِTitle already added"
        //             this.snackbar = true;
        //         }
        //         else{
        //             this.titleconnectivityDetailsDialog = true;
        //         }
        //     }
        //   },
        showTitleConnectivityDetails() {
            if (this.$refs.titleConnectivityPolicy.validate()) {


                const first = this.titleConnectivityPolicyList.find((obj) => { return obj.rowId === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    //load title related devices
                    // this.loadTitleDevcies()
                    this.titleDevicesList = [];
                    this.titleconnectivityDetailsDialog = true;
                }
            }
        },

        loadTitleDevcies(title) {
            let selectedTitle = "";
            if (title == null) {
                selectedTitle = this.selectedUserJob;
            }
            else {
                selectedTitle = title
            }

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleDeviceAmount`,
                params: {
                    CustomerNumber: this.customerNumber,
                    titleId: selectedTitle
                }
            }).then((response) => {
                this.titleDevicesList = [];


                response.data.titleDeviceAmountList.forEach((r) => {

                    this.titleDevicesList.push({
                        device: r.device.name,
                        CoverageAmount: r.amount,
                        id: r.jobId,
                        rowId: r.id,
                        deviceId: r.deviceId
                    });
                })

            });

        },
        titleSchoolcapvalueCHanged() {
            if (this.titleSchoolingHasCap == 'No') {
                this.titleSchoolingCapValue = null;
            }

        },
        getVal(val) {
            if (val == 1) return 'Percent'
            if (val == 2) return 'Amount'
        },
        getConverageBase(val) {
            if (val == 1) return 'Value Based'
            if (val == 2) return 'Brand Based'
        },
        approveDeleteConfirmTitleInsurancePolicy(item) {
            this.editedItem = item;
            this.editedIndex = this.titleInsurancePolicyList.indexOf(item)
            this.DeleteConfirmTitleInsurancePolicyDialog = true
        },
        DeleteConfirmTitleConnectivityDetails(item) {
            this.editedItem = item;
            this.editedIndex = this.titleDevicesList.indexOf(item)
            this.DeleteConfirmTitleConnectivityDetailsDialog = true
        },
        approveDeleteConfirmTitleCarPolicy(item) {
            this.editedItem = item;
            this.editedIndex = this.titleCarPolicyList.indexOf(item)
            this.DeleteConfirmTitleCarPolicyDialog = true
        },
        approveDeleteConfirmTitleSchoolingPolicy(item) {
            this.editedItem = item;
            this.editedIndex = this.titleSchoolingPolicyList.indexOf(item)
            this.DeleteConfirmTitleSchoolingPolicyDialog = true
        },
        approveDeleteConfirmTitleTravelPolicy(item) {
            this.editedItem = item;
            this.editedIndex = this.titleTravelPolicyList.indexOf(item)
            this.DeleteConfirmTitleTravelPolicyDialog = true
        },
        approveDeleteConfirmTitleMedicalPolicy(item) {
            this.editedItem = item;
            this.editedIndex = this.titleMedicalPolicyList.indexOf(item)
            this.DeleteConfirmTitleMedicalPolicyDialog = true
        },
        deleteTitleMedicalPolicy() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleMedial`,
                data: {
                    Id: this.editedItem.rowId,
                    CustomerNumber: this.customerNumber,
                }
            }).then(() => {
                this.titleMedicalPolicyList.splice(this.editedIndex, 1);
                this.DeleteConfirmTitleMedicalPolicyDialog = false;
            });
        },
        deleteTitleConnectivityDevice() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleDeviceAmount`,
                data: {
                    titleId: this.selectedUserJob,
                    CustomerNumber: this.customerNumber,
                    deviceId: this.editedItem.deviceId,
                }
            }).then(() => {
                this.titleDevicesList.splice(this.editedIndex, 1);

                //  var selectedItem = this.titleConnectivityPolicyList
                //  .find((obj) => { return obj.id === this.selectedUserJob;});
                //   var index = this.titleConnectivityPolicyList.indexOf(selectedItem)


                this.DeleteConfirmTitleConnectivityDetailsDialog = false;
            });
        },
        deleteTitleCarPolicy() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleCar`,
                data: {
                    Id: this.editedItem.rowId,
                    CustomerNumber: this.customerNumber,
                }
            }).then(() => {
                this.titleCarPolicyList.splice(this.editedIndex, 1);
                this.DeleteConfirmTitleCarPolicyDialog = false;
            });
        },
        deleteTitleInsurancePolicy() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleInsurance`,
                data: {
                    Id: this.editedItem.rowId,
                    CustomerNumber: this.customerNumber,
                }
            }).then(() => {
                this.titleInsurancePolicyList.splice(this.editedIndex, 1);
                this.DeleteConfirmTitleInsurancePolicyDialog = false;
            });
        },
        deleteTitleTravelPolicy() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleTravel`,
                data: {
                    Id: this.editedItem.rowId,
                    CustomerNumber: this.customerNumber,
                }
            }).then(() => {
                this.titleTravelPolicyList.splice(this.editedIndex, 1);
                this.DeleteConfirmTitleTravelPolicyDialog = false;
            });
        },

        deleteTitleSchoolingPolicy() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleSchooling`,
                data: {
                    Id: this.editedItem.rowId,
                    CustomerNumber: this.customerNumber,
                }
            }).then(() => {
                this.titleSchoolingPolicyList.splice(this.editedIndex, 1);
                this.DeleteConfirmTitleSchoolingPolicyDialog = false;
            });
        },

        deleteTitleMonetaryAwardPolicy() {
            this.$axios({
                method: 'delete',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleMonetaryAward`,
                data: {
                    Id: this.editedItem.rowId,
                    CustomerNumber: this.customerNumber,
                }
            }).then(() => {
                this.titleMonetaryAwardPolicyList.splice(this.editedIndex, 1);
                this.DeleteConfirmTitleMonetaryAwardPolicyDialog = false;
            });
        },
        validateTitleMedicalValues() {
            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleMedicalPolicy.validate()) {

                const first = this.titleMedicalPolicyList.find((obj) => { return obj.id === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleMedial`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            JobId: this.selectedUserJob,
                            CoverageAmount: this.coverageAmt
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleMedialPolicy()
                            this.alertMsg = "Position added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },
        validateTitleCarValues() {
            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleCarPolicy.validate()) {

                const first = this.titleCarPolicyList.find((obj) => { return obj.id === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleCar`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            JobId: this.selectedUserJob,
                            CoverageBase: this.titleCarCoverageBase,
                            CoverageAmount: this.coverageAmt,
                            Brand: this.CarBrand,
                            BrandModel: this.Carmodel
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleCarPolicy()
                            this.alertMsg = "Position added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },
        validateTitleTravelValues() {
            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleTravelPolicy.validate()) {

                const first = this.titleTravelPolicyList.find((obj) => { return obj.id === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleTravel`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            JobId: this.selectedUserJob,
                            CoverageAmount: this.coverageAmt,
                            AmountOrPercent: this.titleTravelPercentAmount
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleTravelPolicy()
                            this.alertMsg = "Position added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },


        validateTitleMonetaryAwardValues() {
            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleMonetaryAwardPolicy.validate()) {

                const first = this.titleMonetaryAwardPolicyList.find((obj) => { return obj.id === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleMonetaryAward`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            JobId: this.selectedUserJob,
                            CoverageAmount: this.coverageAmt,
                            AmountOrPercent: this.titleMonetaryAwardAmount
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleMonetaryAwardPolicy()
                            this.alertMsg = "Position added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },


        validateTitleSchoolingValues() {
            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleSchoolingPolicy.validate()) {

                const first = this.titleSchoolingPolicyList.find((obj) => { return obj.id === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleSchooling`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            JobId: this.selectedUserJob,
                            CoverageAmount: this.coverageAmt,
                            AmountOrPercent: this.titleSchoolingAmount,
                            hasCapValue: (this.titleSchoolingHasCap == null || this.titleSchoolingHasCap == "No") ? false : true,
                            TitleCapValue: this.titleSchoolingCapValue
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleSchoolingPolicy()
                            this.alertMsg = "Position added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },
        validateTitleConnectivityDetailsValue(val) {
            let selectedTitle = "";
            if (val == null) {
                selectedTitle = this.selectedUserJob;
            }
            else {
                selectedTitle = this.editedItem.rowId;
            }

            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleConnectivityDetailsForm.validate()) {

                const first = this.titleDevicesList.find((obj) => { return obj.deviceId === this.selectedDevice; });
                if (first != null) {
                    this.alertMsg = "Device already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleDeviceAmount`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            titleId: selectedTitle,
                            amount: this.coverageAmt,
                            deviceId: this.selectedDevice
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleConnectivityPolicy();
                            this.loadTitleDevcies()
                            this.alertMsg = "device added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },


        validateTitleInsuranceValues() {
            //selectedUserJob
            //coverageAmt
            if (this.$refs.titleInsurancePolicy.validate()) {

                const first = this.titleInsurancePolicyList.find((obj) => { return obj.id === this.selectedUserJob; });
                if (first != null) {
                    this.alertMsg = "ِTitle already added"
                    this.snackbar = true;
                }
                else {
                    this.$axios({
                        method: 'post',
                        url: `${this.$baseURL}/CustomerProfile/Policy/TitleInsurance`,
                        data: {
                            CustomerNumber: this.customerNumber,
                            JobId: this.selectedUserJob,
                            CoverageAmount: this.coverageAmt
                        }
                    }).then(() => {
                        this.$nextTick(() => {
                            this.getTitleInsurancePolicy()
                            this.alertMsg = "Position added successfully to the title"
                            this.snackbar = true;
                        });
                    });
                }

            }


        },
        showTitleLevelCarPolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.titleTravelPolicyList.length == 0) {
                this.getTitleCarPolicy()
            }
            this.coverageAmt = null;
            this.CarBrand = null;
            this.Carmodel = null;
            this.selectedUserJob = null;
            this.coverageAmt = null;
            this.titleLevelCarPolicyDialog = true;
        },
        showTitleLevelMonetaryAwardPolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.titleMonetaryAwardPolicyList.length == 0) {
                this.getTitleMonetaryAwardPolicy()
            }
            this.selectedUserJob = null;
            this.coverageAmt = null;
            this.titleLevelMonetaryAwardPolicyDialog = true;
        },
        showTitleLevelTravelPolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.titleTravelPolicyList.length == 0) {
                this.getTitleTravelPolicy()
            }
            this.selectedUserJob = null;
            this.coverageAmt = null;
            this.titleLevelTravelPolicyDialog = true;
        },
        showTitleLevelSchoolingPolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.titleSchoolingPolicyList.length == 0) {
                this.getTitleSchoolingPolicy()
            }
            this.selectedUserJob = null;
            this.coverageAmt = null;
            this.titleLevelSchoolingPolicyDialog = true;
        },
        showTitleLevelMedicalPolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.titleMedicalPolicyList.length == 0) {
                this.getTitleMedialPolicy()
            }
            this.selectedUserJob = null;
            this.coverageAmt = null;
            this.titleLevelMedicalPolicyDialog = true;
        },
        showTitleLevelConnectivityPolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.devices.length == 0) {
                this.getDevices();
            }
            this.getTitleConnectivityPolicy()
            this.selectedUserJob = null;
            this.coverageAmt = null;
            this.titleLevelConnectivityPolicyDialog = true;
        },

        showTitleLevelInsurancePolicyDialog() {
            if (this.userJobs.length == 0) {
                this.getUserJobs();
            }
            if (this.titleInsurancePolicyList.length == 0) {
                this.getTitleInsurancePolicy()
            }
            this.selectedUserJob = null,
                this.coverageAmt = null;
            this.titleLevelInsurancePolicyDialog = true;
        },
        getTitleCarPolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleCar`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleTravelPolicyList = [];
                response.data.titleTravelCoverage.forEach((r) => {
                    let s = this.userJobs.find((o) => o.jobId == r.jobId);
                    let car = r.brand != null ? this.carsList.find((o) => o.id == r.brand) : null;
                    this.titleTravelPolicyList.push({
                        id: r.jobId,
                        rowId: r.id,
                        jobName: s.job,
                        CoverageBase: r.coverageBase,
                        CoverageAmount: r.coverageAmount,
                        Brand: car == null ? null : car.name,
                        BrandModel: r.BrandModel
                    })
                })

            });
        },
        getTitleTravelPolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleTravel`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleTravelPolicyList = [];
                response.data.titleTravelCoverage.forEach((r) => {
                    let s = this.userJobs.find((o) => o.jobId == r.jobId);
                    this.titleTravelPolicyList.push({
                        id: r.jobId,
                        rowId: r.id,
                        jobName: s.job,
                        CoverageAmount: r.coverageAmount,
                        AmountOrPercent: r.amountOrPercent,
                    })
                })

            });
        },
        getTitleSchoolingPolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleSchooling`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleSchoolingPolicyList = [];
                response.data.titleSchoolingCoverage.forEach((r) => {
                    let s = this.userJobs.find((o) => o.jobId == r.jobId);
                    this.titleSchoolingPolicyList.push({
                        id: r.jobId,
                        rowId: r.id,
                        jobName: s.job,
                        CoverageAmount: r.coverageAmount,
                        AmountOrPercent: r.amountOrPercent,
                        HasCapSupport: r.hasCapValue,
                        CapValue: r.titleCapValue
                    })
                })
            });
        },
        getTitleMonetaryAwardPolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleMonetaryAward`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleMonetaryAwardPolicyList = [];
                response.data.titleMonetaryAwardCoverage.forEach((r) => {
                    let s = this.userJobs.find((o) => o.jobId == r.jobId);
                    this.titleMonetaryAwardPolicyList.push({
                        id: r.jobId,
                        rowId: r.id,
                        jobName: s.job,
                        CoverageAmount: r.coverageAmount,
                        AmountOrPercent: r.amountOrPercent,
                    })
                })

                console.log('titleMonetaryAwardPolicyList')
                console.log(this.titleMonetaryAwardPolicyList)

            });
        },
        getTitleMedialPolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleMedial`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleMedicalPolicyList = [];
                response.data.titleMedicalCoverage.forEach((r) => {
                    let s = this.userJobs.find((o) => o.jobId == r.jobId);
                    this.titleMedicalPolicyList.push({
                        id: r.jobId,
                        rowId: r.id,
                        jobName: s.job,
                        CoverageAmount: r.coverageAmount
                    })
                })

            });
        },

        getTitleInsurancePolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleInsurance`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleInsurancePolicyList = [];
                response.data.titleInsuranceCoverage.forEach((r) => {
                    let s = this.userJobs.find((o) => o.jobId == r.jobId);
                    this.titleInsurancePolicyList.push({
                        id: r.jobId,
                        rowId: r.id,
                        jobName: s.job,
                        CoverageAmount: r.coverageAmount
                    })
                })

            });
        },

        getTitleConnectivityPolicy() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleConnectivity`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                this.titleConnectivityPolicyList = [];
                response.data.titlesConnectivityList.forEach((r) => {
                    this.titleConnectivityPolicyList.push({
                        rowId: r.id,
                        job: r.englishDescription
                    })
                })


            });
        },
        getDevices() {

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/TitleDevice`,
            }).then((response) => {
                this.devices = response.data.devicesList
            });


        },
        editTitleConnectivityDetails(item) {
            console.log('item to editd ...............')
            console.log(item)
            this.editedItem = item;
            this.editedIndex = this.titleConnectivityPolicyList.indexOf(item)
            this.loadTitleDevcies(this.editedItem.rowId)
            this.edittitleconnectivityDetailsDialog = true
        },
        getUserJobs() {

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/JobTitle/UserJobsLookup`,
                params: {
                    CustomerNumber: this.customerNumber,
                }
            }).then((response) => {
                console.log('response response')
                console.log(response)
                this.userJobs = response.data.result
            });


        },
        ValidateCommissionPolicy() {
            if (this.$refs.CommissionPolicyForm.validate()) {
                this.updateCommissionPolicy();
            }
        },
        addMedicalCoverageCategory() {
            if (this.medicalCoverageCategory != '') {
                const index = this.ddlmedicalCoverageCategories.indexOf(this.medicalCoverageCategory, 0);
                if (index == -1) {
                    this.ddlmedicalCoverageCategories.push(this.medicalCoverageCategory)
                }
            }
        },
        removeFromMedicalCategories(item) {
            const index = this.ddlmedicalCoverageCategories.indexOf(item, 0);
            if (index > -1) {
                this.ddlmedicalCoverageCategories.splice(index, 1);
            }
        },

        updateCommissionPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/commissionPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasCommissionPolicy: this.form.hasCommissionPolicy, //Boolean(this.form.hasCommissionPolicy == 'true'?true:false),
                    commissionBasisId: this.form.commissionBasis != null ? Number(this.form.commissionBasis) : null,
                    commissionModelId: this.form.commissionModel != null ? Number(this.form.commissionModel) : null,
                    freqOfPaymentId: this.form.freqOfPayment != null ? Number(this.form.freqOfPayment) : null,
                    otherFreqOfPayment: this.form.otherFreqOfPayment != null ? this.form.otherFreqOfPayment : null,
                    schemeModelId: this.form.schemeModel != null ? Number(this.form.schemeModel) : null,
                    schemeBoundryMin: this.form.schemeBoundryMin != null ? Number(this.form.schemeBoundryMin) : null,
                    schemeBoundryMax: this.form.schemeBoundryMax != null ? Number(this.form.schemeBoundryMax) : null,
                    annualSalesCommission: this.form.annualSalesCommission != null ? Number(this.form.annualSalesCommission) : null,
                    projectBasedBonus: this.form.projectBasedBonus != null ? Number(this.form.projectBasedBonus) : null,
                    productivityBasedBonus: this.form.productivityBasedBonus != null ? Number(this.form.productivityBasedBonus) : null,

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidatePensionPolicy() {
            if (this.$refs.PensionPolicyForm.validate()) {
                this.updatePensionPloicy();
            }
        },
        updatePensionPloicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/PensionPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    hasPansionPolicy: Boolean(this.form.hasPansionPolicy),
                    PansionScheme: this.form.definedPensionScheme != null ? Number(this.form.definedPensionScheme) : null,
                    SchemeDetailsId: this.form.pensionSchemeDetails != null ? Number(this.form.pensionSchemeDetails) : null,
                    VestingRolePercentPerYear: this.form.vestingRolePercPerYear != null ? Number(this.form.vestingRolePercPerYear) : null,
                    HasLoanAgainstPension: Boolean(this.form.loanAgainstPension == 'Yes' ? true : false),
                    LoanAgainstPensionDetails: this.form.loanAgainstPensionDetails != null ? this.form.loanAgainstPensionDetails : null,
                    HasStockOption: Boolean(this.form.stocOptionProgramExist == 'Yes' ? true : false),
                    StocOptionProgram: this.form.stocOptionProgram != null ? Number(this.form.stocOptionProgram) : null,
                    StockOptionId: this.form.selectedEmpStockOption != null ? Number(this.form.selectedEmpStockOption) : null,
                    stocOptionProgramPV: this.form.stocOptionProgram_PV

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateOvertimePolicy() {
            if (this.$refs.OvertimePolicyForm.validate()) {
                this.updateOvertimePolicy();
            }
        },
        updateOvertimePolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/OvertimePolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    hasOvertimePolicy: Boolean(this.form.hasOvertimePolicy),
                    PolicyTargetGroup: this.form.otTargetGroup != null ? Number(this.form.otTargetGroup) : null,
                    DayTimeRate: this.form.dayTimeRate != null ? Number(this.form.dayTimeRate) : null,
                    NightTimeRate: this.form.nightTimeRate != null ? Number(this.form.nightTimeRate) : null,
                    HolidayRate: this.form.holidayRate != null ? Number(this.form.holidayRate) : null,
                    HasCapOnOvertime: Boolean(this.form.capOnOTExists == 'Yes' ? true : false),
                    CapOnOvertimeHours: this.form.capOnOTHours != null ? Number(this.form.capOnOTHours) : null,
                    CapOnOvertimeHoursPerMonth: this.form.capOnOTHoursPerMonth != null ? Number(this.form.capOnOTHoursPerMonth) : null,
                    dayTimeRatePV: Number(this.form.dayTimeRate_PV),
                    nightTimeRatePV: Number(this.form.nightTimeRate_PV),
                    holidayRatePV: Number(this.form.holidayRate_PV),

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateWorkRemotePolicy() {
            if (this.$refs.WorkRemotePolicyForm.validate()) {
                this.updateWorkRemotePolicy();
            }
        },
        updateWorkRemotePolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/WorkRemotePolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    isWorkRemotely: Boolean(this.form.isWorkRemotely),
                    WorkRemoteDeciption: this.form.workRemoteDescription != null ? Number(this.form.workRemoteDescription) : null,
                    workRemoteDaysAllowed: this.form.workRemoteDaysAllowed != null ? Number(this.form.workRemoteDaysAllowed) : null,
                    IsFlexibleWorkingHours: Boolean(this.form.isWorkHoursFlexibility == 'Yes' ? true : false),
                    FlexibleHoursAllowed: this.form.flexibleHoursAllowed != null ? Number(this.form.flexibleHoursAllowed) : null,
                    HasUnconditionalLeave: Boolean(this.form.isUnConditionalLeaveOption == 'Yes' ? true : false),
                    UnConditionalLeaveOptions: this.form.unconditionalLeaveOtions != null ? this.form.unconditionalLeaveOtions.toString() : null,

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateMedicalPolicy() {
            if (this.$refs.MedicalPolicyForm.validate()) {
                this.updateMedicalPolicy();
            }
        },
        updateMedicalPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/MedicalPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,

                    HasMedicalPolicy: Boolean(this.form.iskMedicalPolicyExist),
                    medicalCoverageScope: this.form.medicalCoverageScope != null ? Number(this.form.medicalCoverageScope) : null,
                    medicalNumOfDependants: this.form.medicalNumOfDependants != null ? Number(this.form.medicalNumOfDependants) : null,
                    medicalCoverageType: this.form.medicalCoverageType != null ? Number(this.form.medicalCoverageType) : null,
                    MedicalSchemeType: this.form.medicalSchemeType != null ? Number(this.form.medicalSchemeType) : null,
                    CoverageCategories: this.ddlmedicalCoverageCategories.length > 0 ? this.ddlmedicalCoverageCategories.toString() : '',
                    ServiceProvider: this.form.medicalServiceProvider != null ? Number(this.form.medicalServiceProvider) : null,
                    SrviceProviderCompanyId: this.form.srviceProviderCompanyId != null ? this.form.srviceProviderCompanyId : null,
                    HasLifeInsurencePolicy: Boolean(this.form.isLifeInsurancePolicy),
                    LifeInsuranceTypeOfCoverage: this.form.lifeInTypeOfCoverage != null ? Number(this.form.lifeInTypeOfCoverage) : null,
                    LifeInsuranceCoverageValue: this.form.valueOfLifeInCoverage != null ? Number(this.form.valueOfLifeInCoverage) : null,
                    LifeInsurenceServiceProvider: this.form.lifeInServiceProvider != null ? Number(this.form.lifeInServiceProvider) : null,
                    lifeInServiceProvider: this.form.lifeInServiceProvider != null ? Number(this.form.lifeInServiceProvider) : null,
                    lifeInServiceCompany: this.form.lifeInServiceCompany != null ? Number(this.form.lifeInServiceCompany) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidatePhysicalDisabilityPolicy() {
            if (this.$refs.PhysicalDisabilityPolicyForm.validate()) {
                this.updatePhysicalDisabilityPolicy();
            }
        },
        updatePhysicalDisabilityPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/PhysicalDisabilityPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasPhysicalDisabilityPolicy: Boolean(this.form.hasPhysicalDisabilityPolicy),
                    PhysicalDisTypeOfCoverage: this.form.physicalDisTypeOfCoverage != null ? Number(this.form.physicalDisTypeOfCoverage) : null,
                    physicalDisServiceProvider: this.form.physicalDisServiceProvider != null ? Number(this.form.physicalDisServiceProvider) : null,

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateTransportationPolicy() {
            if (this.$refs.TransportationPolicyForm.validate()) {
                this.updateTransportationPolicy();
            }
        },
        updateTransportationPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/TransportationPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasTransportationPolicy: Boolean(this.form.hasTransportationPolicy),
                    TransportationSupportAmount: this.form.transportationSupportAmount != null ? Number(this.form.transportationSupportAmount) : null,
                    TransportationModel: this.form.transportationModel,
                    TransportationBusModel: this.form.transportationBusModel,
                    TransportationPickupDropoffModel: this.form.transportationPickupDropoffModel,
                    TransportationMalesVsFemales: this.form.transportationMalesVsFemales
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateCarPolicy() {
            if (this.$refs.CarPolicyForm.validate()) {
                this.updateCarPolicy();
            }
        },
        updateCarPolicy() {
            let data = {
                Id: this.form.id == null ? -1 : this.form.id,
                CustomerNumber: this.customerNumber,

                HasCarPolicy: Boolean(this.form.hasCarPolicy),
                CarPolicyPurpose: this.form.carPolicyPurpose != null ? Number(this.form.carPolicyPurpose) : null,
                CarPolicyFunding: this.form.carPolicyFunding != null ? Number(this.form.carPolicyFunding) : null,
                YearstoOwn: this.form.yearstoOwn != null ? Number(this.form.yearstoOwn) : null,
                PercentPaidByEmployee: this.form.PercentPaidByEmployee != null ? Number(this.form.PercentPaidByEmployee) : null,
                CarSelectionBase: this.form.carSelctionBase != null ? Number(this.form.carSelctionBase) : null,
                carInsuranceProvider: this.form.carInsuranceProvider != null ? Number(this.form.carInsuranceProvider) : null,
                selectedCar: this.form.selectedCar != null ? Number(this.form.selectedCar) : null,

            };
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/CarPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidatePayAdvancePolicy() {
            if (this.$refs.PayAdvancePolicyForm.validate()) {
                this.updatePayAdvancePolicy();
            }
        },
        updatePayAdvancePolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/PayAdvancePolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,

                    HasPayAdvancePolicy: Boolean(this.form.hasPayAdvancePolicy),
                    SalaryAdvancePercent: this.form.salaryAdvancePercent != null ? Number(this.form.salaryAdvancePercent) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        validatePayrollOptions() {
            if (this.$refs.PayrollPolicyForm.validate()) {
                this.updatePayrollOptions();
            }
        },
        updatePayrollOptions() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/payrollpolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,

                    hasPayrollPolicy: Boolean(this.form.hasPayAdvancePolicy),
                    PayrolOPtion: this.form.PayrollOption_PV != null ? Number(this.form.PayrollOption_PV) : null,
                    payrollBankId: this.form.PayrolBank != null ? Number(this.form.PayrolBank) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateStaffLoanPolicy() {
            if (this.$refs.StaffLoanPolicyForm.validate()) {
                this.updateStaffLoanPolicy();
            }
        },
        updateStaffLoanPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/StaffLoanPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,

                    HasStaffLoanPolicy: Boolean(this.form.hasStaffLoanPolicy),
                    SalaryLoanPercent: this.form.salaryLoanPercent != null ? Number(this.form.salaryLoanPercent) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateConnectivityPolicy() {
            if (this.$refs.ConnectivityPolicyForm.validate()) {
                this.updateConnectivityPolicy();
            }
        },
        updateConnectivityPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/ConnectivityPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasConnectivityPolicy: this.form.connectivityPolicy != null ? (this.form.connectivityPolicy == 1 ? true : false) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateSchoolingFeesSupportPolicy() {
            if (this.$refs.SchoolingFeesSupportPolicyForm.validate()) {
                this.updateeSchoolingFeesSupportPolicy();
            }
        },
        updateeSchoolingFeesSupportPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/SchoolingPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasSchoolingFeesSupport: Boolean(this.form.hasSchoolingFeesSupport),
                    SchoolingSupportType: this.form.schoolingSupportType != null ? Number(this.form.schoolingSupportType) : null,
                    schoolingSupportValue: this.form.schoolingSupportValue != null ? Number(this.form.schoolingSupportValue) : null,
                    hasCapOPtion: Boolean(this.form.hasCapOPtion == 'Yes' ? true : false),
                    capValue: this.form.capValue != null ? Number(this.form.capValue) : null,

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateMarriageCashAwardPolicy() {
            if (this.$refs.MarriageCashAwardForm.validate()) {
                this.updateMarriageCashAwardPolicy();
            }
        },
        updateMarriageCashAwardPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/MarriagePolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasMarriageCashAward: Boolean(this.form.hasMarriageCashAward),
                    MarriageAwardType: this.form.marriageAwardType != null ? Number(this.form.marriageAwardType) : null,
                    MarriageAwardValue: this.form.marriageAwardValue != null ? Number(this.form.marriageAwardValue) : null,
                    HasMarriageCapOPtion: Boolean(this.form.hasMarriageCapOPtion == 'Yes' ? true : false),
                    MarriageCapValue: this.form.marriageCapValue != null ? Number(this.form.marriageCapValue) : null,

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateNewBornCashAwardPolicy() {
            if (this.$refs.NewBornCashAwardForm.validate()) {
                this.updateNewBornCashAwardPolicy();
            }
        },
        updateNewBornCashAwardPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/NewBornPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasNewBornCashAward: Boolean(this.form.hasNewBornCashAward),
                    NewBornAwardType: this.form.newBornAwardType != null ? Number(this.form.newBornAwardType) : null,
                    NewBornAwardValue: this.form.newBornAwardValue != null ? Number(this.form.newBornAwardValue) : null,
                    HasNewBornCapOPtion: Boolean(this.form.hasNewBornCapOPtion == 'Yes' ? true : false),
                    NewBornCapValue: this.form.newBornCapValue != null ? Number(this.form.newBornCapValue) : null,

                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateCashSupportforFirstDegRelDeathPolicy() {
            if (this.$refs.CashSupportforFirstDegRelDeathPolicyForm.validate()) {
                this.updateCashSupportforFirstDegRelDeathPolicy();
            }
        },
        updateCashSupportforFirstDegRelDeathPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/DeathSupportPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,

                    HasCashSupportforFirstDegRelDeath: Boolean(this.form.hasCashSupportforFirstDegRelDeath),
                    RelativeDeathSupportType: this.form.relativeDeathSupportType != null ? Number(this.form.relativeDeathSupportType) : null,
                    RelativeDeathSupportValue: this.form.relativeDeathSupportValue != null ? Number(this.form.relativeDeathSupportValue) : null,
                    HasRelativeDeathCapOPtion: Boolean(this.form.hasRelativeDeathCapOPtion == 'Yes' ? true : false),
                    RelativeDeathSupportCapValue: this.form.relativeDeathSupportCapValue != null ? Number(this.form.relativeDeathSupportCapValue) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },

        ValidateHajAndUmrahSupportPolicy() {
            if (this.$refs.HajAndUmrahSupportPolicyForm.validate()) {
                this.updateHajAndUmrahSupportPolicy();
            }
        },
        updateHajAndUmrahSupportPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/HajPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasHajAndUmrahSupport: Boolean(this.form.hasHajAndUmrahSupport),
                    HajAndUmrahSupportType: this.form.hajAndUmrahSupportType != null ? Number(this.form.hajAndUmrahSupportType) : null,
                    HajAndUmrahSupportValue: this.form.hajAndUmrahSupportValue != null ? Number(this.form.hajAndUmrahSupportValue) : null,
                    HashajAndUmrahCapOPtion: Boolean(this.form.hashajAndUmrahCapOPtion == 'Yes' ? true : false),
                    HajAndUmrahCapValue: this.form.hajAndUmrahCapValue != null ? Number(this.form.hajAndUmrahCapValue) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateMonetaryAwardPolicy() {
            if (this.$refs.MonetaryAwardPForm.validate()) {
                this.updateMonetaryAwardPolicy();
            }
        },
        updateMonetaryAwardPolicy() {
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/MonetaryPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasMonetaryAwardPolicy: Boolean(this.form.hasMonetaryAwardPolicy),
                    MonetaryAwardType: this.form.monetaryAwardType != null ? Number(this.form.monetaryAwardType) : null,
                    MonetaryAwardValue: this.form.monetaryAwardValue != null ? Number(this.form.monetaryAwardValue) : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        ValidateNonMonetaryAwardPolicy() {
            if (this.$refs.NonMonetaryAwardPolicyForm.validate()) {
                this.updateNonMonetaryAwardPolicy();
            }
        },
        updateNonMonetaryAwardPolicy() {
            let data = {
                Id: this.form.id == null ? -1 : this.form.id,
                CustomerNumber: this.customerNumber,
                HasNonMonetaryAwardPolicy: Boolean(this.form.hasNonMonetaryAwardPolicy),
                NonMonetaryAwardType: this.form.nonMonetaryAwardType != null ? this.form.nonMonetaryAwardType.toString() : null,
                OtherNonMonetaryAward: this.form.otherNonMonetaryAward != null ? this.form.otherNonMonetaryAward : null,
            };
            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/NonMonetaryPolicy`,
                headers: { 'Content-Type': 'application/json' },
                data: data
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        SavePolicies() {


            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.$axios({
                method: 'put',
                url: `${this.$baseURL}/CustomerProfile/Policy`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    Id: this.form.id == null ? -1 : this.form.id,
                    CustomerNumber: this.customerNumber,
                    HasCommissionPolicy: Boolean(this.form.hasCommissionPolicy == 'true' ? true : false),
                    commissionBasisId: this.form.commissionBasis != null ? Number(this.form.commissionBasis) : null,
                    commissionModelId: this.form.commissionModel != null ? Number(this.form.commissionModel) : null,
                    freqOfPaymentId: this.form.freqOfPayment != null ? Number(this.form.freqOfPayment) : null,
                    schemeModelId: this.form.schemeModel != null ? Number(this.form.schemeModel) : null,
                    schemeBoundryMin: this.form.schemeBoundryMin != null ? Number(this.form.schemeBoundryMin) : null,
                    schemeBoundryMax: this.form.schemeBoundryMax != null ? Number(this.form.schemeBoundryMax) : null,
                    hasPansionPolicy: Boolean(this.form.hasPansionPolicy == 'true' ? true : false),
                    PansionScheme: this.form.definedPensionScheme != null ? Number(this.form.definedPensionScheme) : null,
                    SchemeDetailsId: this.form.pensionSchemeDetails != null ? Number(this.form.pensionSchemeDetails) : null,
                    VestingRolePercentPerYear: this.form.vestingRolePercPerYear != null ? Number(this.form.vestingRolePercPerYear) : null,
                    HasLoanAgainstPension: Boolean(this.form.loanAgainstPension == 'Yes' ? true : false),
                    LoanAgainstPensionDetails: this.form.loanAgainstPensionDetails != null ? this.form.loanAgainstPensionDetails : null,
                    HasStockOption: Boolean(this.form.stocOptionProgramExist == 'Yes' ? true : false),
                    StocOptionProgram: this.form.stocOptionProgram != null ? Number(this.form.stocOptionProgram) : null,
                    StockOptionId: this.form.selectedEmpStockOption != null ? Number(this.form.selectedEmpStockOption) : null,

                    hasOvertimePolicy: Boolean(this.form.hasOvertimePolicy == 'true' ? true : false),
                    PolicyTargetGroup: this.form.otTargetGroup != null ? Number(this.form.otTargetGroup) : null,
                    DayTimeRate: this.form.dayTimeRate != null ? Number(this.form.dayTimeRate) : null,
                    NightTimeRate: this.form.nightTimeRate != null ? Number(this.form.nightTimeRate) : null,
                    HolidayRate: this.form.holidayRate != null ? Number(this.form.holidayRate) : null,
                    HasCapOnOvertime: Boolean(this.form.capOnOTExists == 'Yes' ? true : false),
                    CapOnOvertimeHours: this.form.capOnOTHours != null ? Number(this.form.capOnOTHours) : null,
                    CapOnOvertimeHoursPerMonth: this.form.capOnOTHoursPerMonth != null ? Number(this.form.capOnOTHoursPerMonth) : null,

                    isWorkRemotely: Boolean(this.form.isWorkRemotely == 'true' ? true : false),
                    WorkRemoteDeciption: this.form.workRemoteDescription != null ? this.form.workRemoteDescription : null,
                    workRemoteDaysAllowed: this.form.workRemoteDaysAllowed != null ? Number(this.form.workRemoteDaysAllowed) : null,
                    IsFlexibleWorkingHours: Boolean(this.form.isWorkHoursFlexibility == 'Yes' ? true : false),
                    FlexibleHoursAllowed: this.form.flexibleHoursAllowed != null ? Number(this.form.flexibleHoursAllowed) : null,
                    HasUnconditionalLeave: Boolean(this.form.isUnConditionalLeaveOption == 'Yes' ? true : false),
                    UnConditionalLeaveOptions: this.form.unconditionalLeaveOtions != null ? this.form.unconditionalLeaveOtions.toString() : null,

                    HasMedicalPolicy: Boolean(this.form.iskMedicalPolicyExist == 'Yes' ? true : false),
                    medicalCoverageScope: this.form.medicalCoverageScope != null ? Number(this.form.medicalCoverageScope) : null,
                    medicalNumOfDependants: this.form.medicalNumOfDependants != null ? Number(this.form.medicalNumOfDependants) : null,
                    medicalCoverageType: this.form.medicalCoverageType != null ? Number(this.form.medicalCoverageType) : null,
                    MedicalSchemeType: this.form.medicalSchemeType != null ? Number(this.form.medicalSchemeType) : null,
                    CoverageCategories: this.form.medicalCoverageCategories != null ? this.form.medicalCoverageCategories.toString() : null,
                    ServiceProvider: this.form.medicalServiceProvider != null ? Number(this.form.medicalServiceProvider) : null,
                    SrviceProviderCompany: this.form.medicalServiceProviderCompany != null ? this.form.medicalServiceProviderCompany : null,
                    HasLifeInsurencePolicy: Boolean(this.form.isLifeInsurancePolicy == 'Yes' ? true : false),
                    LifeInsuranceTypeOfCoverage: this.form.lifeInTypeOfCoverage != null ? Number(this.form.lifeInTypeOfCoverage) : null,
                    LifeInsuranceCoverageValue: this.form.valueOfLifeInCoverage != null ? Number(this.form.valueOfLifeInCoverage) : null,
                    LifeInsurenceServiceProvider: this.form.lifeInServiceProvider != null ? Number(this.form.lifeInServiceProvider) : null,
                    HasPhysicalDisabilityPolicy: Boolean(this.form.hasPhysicalDisabilityPolicy == 'true' ? true : false),
                    PhysicalDisTypeOfCoverage: this.form.physicalDisTypeOfCoverage != null ? Number(this.form.physicalDisTypeOfCoverage) : null,
                    physicalDisServiceProvider: this.form.physicalDisServiceProvider != null ? Number(this.form.physicalDisServiceProvider) : null,

                    HasTransportationPolicy: Boolean(this.form.hasTransportationPolicy == 'true' ? true : false),
                    TransportationSupportAmount: this.form.transportationSupportAmount != null ? Number(this.form.transportationSupportAmount) : null,
                    HasCarPolicy: Boolean(this.form.hasCarPolicy == 'true' ? true : false),
                    CarPolicyPurpose: this.form.carPolicyPurpose != null ? Number(this.form.carPolicyPurpose) : null,
                    CarPolicyFunding: this.form.carPolicyFunding != null ? Number(this.form.carPolicyFunding) : null,
                    YearstoOwn: this.form.yearstoOwn != null ? Number(this.form.yearstoOwn) : null,
                    PercentPaidByEmployee: this.form.PercentPaidByEmployee != null ? Number(this.form.PercentPaidByEmployee) : null,
                    CarSelectionBase: this.form.carSelctionBase != null ? Number(this.form.carSelctionBase) : null,

                    HasPayAdvancePolicy: Boolean(this.form.hasPayAdvancePolicy == 'true' ? true : false),
                    SalaryAdvancePercent: this.form.salaryAdvancePercent != null ? Number(this.form.salaryAdvancePercent) : null,
                    HasStaffLoanPolicy: Boolean(this.form.hasStaffLoanPolicy == 'true' ? true : false),
                    SalaryLoanPercent: this.form.salaryLoanPercent != null ? Number(this.form.salaryLoanPercent) : null,
                    HasConnectivityPolicy: this.form.connectivityPolicy != null ? (this.form.connectivityPolicy == 1 ? true : false) : null,
                    HasSchoolingFeesSupport: Boolean(this.form.hasSchoolingFeesSupport == 'true' ? true : false),
                    SchoolingSupportType: this.form.schoolingSupportType != null ? Number(this.form.schoolingSupportType) : null,
                    schoolingSupportValue: this.form.schoolingSupportValue != null ? Number(this.form.schoolingSupportValue) : null,
                    hasCapOPtion: Boolean(this.form.hasCapOPtion == 'Yes' ? true : false),
                    capValue: this.form.capValue != null ? Number(this.form.capValue) : null,

                    HasMarriageCashAward: Boolean(this.form.hasMarriageCashAward == 'true' ? true : false),
                    MarriageAwardType: this.form.marriageAwardType != null ? Number(this.form.marriageAwardType) : null,
                    MarriageAwardValue: this.form.marriageAwardValue != null ? Number(this.form.marriageAwardValue) : null,
                    HasMarriageCapOPtion: Boolean(this.form.hasMarriageCapOPtion == 'Yes' ? true : false),
                    MarriageCapValue: this.form.marriageCapValue != null ? Number(this.form.marriageCapValue) : null,

                    HasNewBornCashAward: Boolean(this.form.hasNewBornCashAward == 'true' ? true : false),
                    NewBornAwardType: this.form.newBornAwardType != null ? Number(this.form.newBornAwardType) : null,
                    NewBornAwardValue: this.form.newBornAwardValue != null ? Number(this.form.newBornAwardValue) : null,
                    HasNewBornCapOPtion: Boolean(this.form.hasNewBornCapOPtion == 'Yes' ? true : false),
                    NewBornCapValue: this.form.newBornCapValue != null ? Number(this.form.newBornCapValue) : null,

                    HasCashSupportforFirstDegRelDeath: Boolean(this.form.hasCashSupportforFirstDegRelDeath == 'true' ? true : false),
                    RelativeDeathSupportType: this.form.relativeDeathSupportType != null ? Number(this.form.relativeDeathSupportType) : null,
                    RelativeDeathSupportValue: this.form.relativeDeathSupportValue != null ? Number(this.form.relativeDeathSupportValue) : null,
                    HasRelativeDeathCapOPtion: Boolean(this.form.hasRelativeDeathCapOPtion == 'Yes' ? true : false),
                    RelativeDeathSupportCapValue: this.form.relativeDeathSupportCapValue != null ? Number(this.form.relativeDeathSupportCapValue) : null,

                    HasHajAndUmrahSupport: Boolean(this.form.hasHajAndUmrahSupport == 'true' ? true : false),
                    HajAndUmrahSupportType: this.form.hajAndUmrahSupportType != null ? Number(this.form.hajAndUmrahSupportType) : null,
                    HajAndUmrahSupportValue: this.form.hajAndUmrahSupportValue != null ? Number(this.form.hajAndUmrahSupportValue) : null,
                    HashajAndUmrahCapOPtion: Boolean(this.form.hashajAndUmrahCapOPtion == 'Yes' ? true : false),
                    HajAndUmrahCapValue: this.form.hajAndUmrahCapValue != null ? Number(this.form.hajAndUmrahCapValue) : null,
                    HasMonetaryAwardPolicy: Boolean(this.form.hasMonetaryAwardPolicy == 'true' ? true : false),
                    MonetaryAwardType: this.form.monetaryAwardType != null ? Number(this.form.monetaryAwardType) : null,
                    MonetaryAwardValue: this.form.monetaryAwardValue != null ? Number(this.form.monetaryAwardValue) : null,
                    HasNonMonetaryAwardPolicy: Boolean(this.form.hasNonMonetaryAwardPolicy == 'true' ? true : false),
                    NonMonetaryAwardType: this.form.nonMonetaryAwardType != null ? this.form.nonMonetaryAwardType.toString() : null,
                    OtherNonMonetaryAward: this.form.otherNonMonetaryAward != null ? this.form.otherNonMonetaryAward : null,
                }
            }).then((response) => {

                this.$bvModal.msgBoxOk('Policy Updated successfully', {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                });
                this.form.id = response.data;

            });
        },
        AddCategory() {
            if (this.medicalCoverageCategory != '') {
                console.log(this.form.medicalCoverageCategories)
                let cat = this.form.medicalCoverageCategories.filter(c => c.toLowerCase() == this.medicalCoverageCategory.toLowerCase())

                // let indexOf = (arr, q) => arr.findIndex(item => q.toLowerCase() === item.toLowerCase());
                if (cat.length == 0) {
                    this.form.medicalCoverageCategories.push(this.medicalCoverageCategory);
                }
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        hasCommissionPolicyChanged(state) {
            if (!state) {
                this.$v.$reset();
                this.form.commissionBasis = null;
                this.form.commissionModel = null;
                this.form.freqOfPayment = null;
                this.form.otherFreqOfPayment = null;
                this.form.schemeModel = null;
                this.form.schemeBoundryMin = null;
                this.form.schemeBoundryMax = null;
                this.form.annualSalesCommission = null;
                this.form.projectBasedBonus = null;
                this.form.productivityBasedBonus = null;
            }
        },
        hasPansionPolicyChanged(state) {
            if (state == false) {
                this.$v.$reset();
                this.form.definedPensionScheme = null;
                this.form.pensionSchemeDetails = null;
                this.form.vestingRolePercPerYear = null;
                this.form.loanAgainstPension = 'No';
                this.form.loanAgainstPensionDetails = null;
                this.form.stocOptionProgramExist = 'No';
                this.form.stocOptionProgram = null;
                this.form.selectedEmpStockOption = null;
            }
        },
        hasOvertimePolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.otTargetGroup = null;
                this.form.dayTimeRate = null;
                this.form.nightTimeRate = null;
                this.form.holidayRate = null;
                this.form.capOnOTExists = 'No';
                this.form.capOnOTHours = null;
                this.form.capOnOTHoursPerMonth = null;
            }
        },
        isWorkRemotelyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.workRemoteDescription = null;
                this.form.workRemoteDaysAllowed = null;
                this.form.isWorkHoursFlexibility = 'No';
                this.form.flexibleHoursAllowed = null;
                this.form.isUnConditionalLeaveOption = 'No';
                this.form.unconditionalLeaveOtions = null;
            }
        },
        iskMedicalPolicyExistChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.medicalCoverageScope = null;
                this.form.medicalNumOfDependants = null;
                this.form.medicalCoverageType = null;
                this.form.medicalSchemeType = null;
                this.form.medicalCoverageCategories = null;
                this.form.medicalServiceProvider = null;
                this.form.medicalServiceProviderCompany = null;
                this.form.isLifeInsurancePolicy = 'No';
                this.form.lifeInTypeOfCoverage = null;
                this.form.valueOfLifeInCoverage = null;
                this.form.healthInServiceProvider = null;
            }
        },
        hasPhysicalDisabilityPolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.physicalDisTypeOfCoverage = null;
                this.form.physicalDisServiceProvider = null;
            }
        },
        hasTransportationPolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.transportationSupportAmount = null;
            }
        },
        hasCarPolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.carPolicyPurpose = null;
                this.form.carPolicyFunding = null;
                this.form.yearstoOwn = null;
                this.form.PercentPaidByEmployee = null;
                this.form.carSelctionBase = null;
            }
        },
        PayrollPaymentMethodChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.PayrollOption_PV = 0;
            }
        },
        hasPayAdvancePolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.salaryAdvancePercent = null;
            }
        },
        hasStaffLoanPolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.salaryLoanPercent = null;
            }
        },
        hasSchoolingFeesSupportChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.schoolingSupportType = null;
                this.form.schoolingSupportValue = null;
                this.form.hasCapOPtion = 'No';
                this.form.capValue = null;
            }
        },
        hasMarriageCashAwardChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.marriageAwardType = null;
                this.form.marriageAwardValue = null;
                this.form.hasMarriageCapOPtion = 'No';
                this.form.marriageCapValue = null;
            }
        },
        hasNewBornCashAwardChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.newBornAwardType = null;
                this.form.newBornAwardValue = null;
                this.form.hasNewBornCapOPtion = 'No';
                this.form.newBornCapValue = null;
            }
        },
        hasCashSupportforFirstDegRelDeathChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.relativeDeathSupportType = null;
                this.form.relativeDeathSupportValue = null;
                this.form.hasRelativeDeathCapOPtion = 'No';
                this.form.relativeDeathSupportCapValue = null;
            }
        },
        hasHajAndUmrahSupportChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.hajAndUmrahSupportType = null;
                this.form.hajAndUmrahSupportValue = null;
                this.form.hashajAndUmrahCapOPtion = 'No';
                this.form.hajAndUmrahCapValue = null;
            }
        },
        hasMonetaryAwardPolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.monetaryAwardType = null;
                this.form.monetaryAwardValue = null;
            }
        },
        hasNonMonetaryAwardPolicyChanged(state) {
            if (state == 'false') {
                this.$v.$reset();
                this.form.nonMonetaryAwardType = [];
                this.form.otherNonMonetaryAward = null;
            }
        },
        toggle() {
            this.$nextTick(() => {
                if (this.likesAll) {
                    this.visiblePolicies = []
                } else {
                    this.visiblePolicies = this.policiesEntries
                }
            })
        },
        getPolicy(selctedCustNumber) {

            // load lookups :
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy/lookups`,
                headers: { 'Content-Type': 'application/json' },
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {

                    console.log('response.data ........................')
                    console.log(response.data.lookups)
                    this.PayrolBanks =
                        response.data.lookups.banks.map(p => ({
                            id: p.id,
                            name: p.name
                        }));

                    this.carInsuranceProviders =
                        response.data.lookups.carInsuranceProviders.map(p => ({
                            id: p.id,
                            name: p.name
                        }));

                    this.carsList =
                        response.data.lookups.cars.map(p => ({
                            id: p.id,
                            name: p.name
                        }));

                    this.lifeInServiceCompanies =
                        response.data.lookups.lifeInsuranceProviders.map(p => ({
                            id: p.id,
                            name: p.name
                        }));

                    this.srviceProviderCompanies =
                        response.data.lookups.medicalInsuranceProviders.map(p => ({
                            id: p.id,
                            name: p.name
                        }));

                }
            });

            if (selctedCustNumber == null)
                selctedCustNumber = this.customerNumber;
            else
                this.currentSelectedCustomer = selctedCustNumber;

            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/Policy?CustomerNumber=${this.customerNumber}`,
                headers: { 'Content-Type': 'application/json' },
            }).then((response) => {
                if (JSON.parse(response.data.status) == true) {



                    this.form.id = response.data.policy.id;
                    this.form.hasCommissionPolicy = Boolean(response.data.policy.hasCommissionPolicy);
                    this.form.commissionBasis = response.data.policy.commissionBasisId != null ? Number(response.data.policy.commissionBasisId) : null;
                    this.form.commissionModel = response.data.policy.commissionModelId != null ? Number(response.data.policy.commissionModelId) : null;
                    this.form.freqOfPayment = response.data.policy.freqOfPaymentId != null ? Number(response.data.policy.freqOfPaymentId) : null;
                    this.form.otherFreqOfPayment = response.data.policy.otherFreqOfPayment != null ? response.data.policy.otherFreqOfPayment : null;
                    this.form.schemeModel = response.data.policy.schemeModelId != null ? Number(response.data.policy.schemeModelId) : null;
                    this.form.schemeBoundryMin = response.data.policy.schemeBoundryMin != null ? Number(response.data.policy.schemeBoundryMin) : null;
                    this.form.schemeBoundryMax = response.data.policy.schemeBoundryMax != null ? Number(response.data.policy.schemeBoundryMax) : null;
                    this.form.annualSalesCommission = response.data.policy.annualSalesCommission != null ? Number(response.data.policy.annualSalesCommission) : null;
                    this.form.projectBasedBonus = response.data.policy.projectBasedBonus != null ? Number(response.data.policy.projectBasedBonus) : null;
                    this.form.productivityBasedBonus = response.data.policy.productivityBasedBonus != null ? Number(response.data.policy.productivityBasedBonus) : null;



                    this.form.hasPansionPolicy = Boolean(response.data.policy.hasPansionPolicy);
                    this.form.definedPensionScheme = response.data.policy.pansionScheme != null ? Number(response.data.policy.pansionScheme) : null
                    this.form.pensionSchemeDetails = response.data.policy.schemeDetailsId != null ? Number(response.data.policy.schemeDetailsId) : null;
                    this.form.vestingRolePercPerYear = response.data.policy.vestingRolePercentPerYear != null ? Number(response.data.policy.vestingRolePercentPerYear) : null;
                    this.form.loanAgainstPension = response.data.policy.hasLoanAgainstPension == null ? 'No' : 'Yes';
                    this.form.loanAgainstPensionDetails = response.data.policy.loanAgainstPensionDetails != null ? response.data.policy.loanAgainstPensionDetails : null;
                    this.form.stocOptionProgramExist = response.data.policy.hasStockOption ? 'Yes' : 'No';
                    this.form.stocOptionProgram = response.data.policy.stocOptionProgram != null ? Number(response.data.policy.stocOptionProgram) : null;
                    this.form.stocOptionProgram_PV = response.data.policy.stocOptionProgramPV != null ? Number(response.data.policy.stocOptionProgramPV) : 0;
                    this.form.selectedEmpStockOption = response.data.policy.stockOptionId != null ? Number(response.data.policy.stockOptionId) : null;

                    this.form.hasOvertimePolicy = response.data.policy.hasOvertimePolicy;
                    this.form.otTargetGroup = response.data.policy.policyTargetGroup != null ? Number(response.data.policy.policyTargetGroup) : null;
                    this.form.dayTimeRate = response.data.policy.dayTimeRate != null ? Number(response.data.policy.dayTimeRate) : null;
                    this.form.nightTimeRate = response.data.policy.nightTimeRate != null ? Number(response.data.policy.nightTimeRate) : null;
                    this.form.holidayRate = response.data.policy.holidayRate != null ? Number(response.data.policy.holidayRate) : null;
                    this.form.capOnOTExists = response.data.policy.hasCapOnOvertime == true ? 'Yes' : 'No';
                    this.form.capOnOTHours = response.data.policy.capOnOvertimeHours != null ? Number(response.data.policy.capOnOvertimeHours) : null;
                    this.form.capOnOTHoursPerMonth = response.data.policy.capOnOvertimeHoursPerMonth != null ? Number(response.data.policy.capOnOvertimeHoursPerMonth) : null;

                    this.form.isWorkRemotely = Boolean(response.data.policy.isWorkRemotely);
                    this.form.workRemoteDescription = response.data.policy.workRemoteDeciption != null ? Number(response.data.policy.workRemoteDeciption) : null;
                    this.form.workRemoteDaysAllowed = response.data.policy.workRemoteDaysAllowed != null ? Number(response.data.policy.workRemoteDaysAllowed) : null;
                    this.form.isWorkHoursFlexibility = response.data.policy.isFlexibleWorkingHours == true ? 'Yes' : 'No';
                    this.form.flexibleHoursAllowed = response.data.policy.flexibleHoursAllowed != null ? Number(response.data.policy.flexibleHoursAllowed) : null;
                    this.form.isUnConditionalLeaveOption = response.data.policy.hasUnconditionalLeave == true ? 'Yes' : 'No';
                    this.form.unconditionalLeaveOtions = Number(response.data.policy.unConditionalLeaveOptions);

                    this.form.iskMedicalPolicyExist = Number(response.data.policy.hasMedicalPolicy);
                    this.form.medicalCoverageScope = response.data.policy.medicalCoverageScope != null ? Number(response.data.policy.medicalCoverageScope) : null;
                    this.form.medicalNumOfDependants = response.data.policy.medicalNumOfDependants != null ? Number(response.data.policy.medicalNumOfDependants) : null;
                    this.form.medicalCoverageType = response.data.policy.medicalCoverageType != null ? Number(response.data.policy.medicalCoverageType) : null;
                    this.form.medicalSchemeType = response.data.policy.medicalSchemeType != null ? Number(response.data.policy.medicalSchemeType) : null;
                    this.ddlmedicalCoverageCategories = response.data.policy.coverageCategories != null ? response.data.policy.coverageCategories.split(',') : [];
                    this.form.medicalServiceProvider = response.data.policy.serviceProvider != null ? Number(response.data.policy.serviceProvider) : null;
                    this.form.srviceProviderCompanyId = response.data.policy.srviceProviderCompanyId != null ? Number(response.data.policy.srviceProviderCompanyId) : null;
                    this.form.isLifeInsurancePolicy = Number(response.data.policy.hasLifeInsurencePolicy);
                    this.form.lifeInTypeOfCoverage = response.data.policy.lifeInsuranceTypeOfCoverage != null ? Number(response.data.policy.lifeInsuranceTypeOfCoverage) : null;
                    this.form.valueOfLifeInCoverage = response.data.policy.lifeInsuranceCoverageValue != null ? Number(response.data.policy.lifeInsuranceCoverageValue) : null;
                    this.form.lifeInServiceProvider = response.data.policy.lifeInsurenceServiceProvider != null ? Number(response.data.policy.lifeInsurenceServiceProvider) : null;
                    this.form.lifeInServiceCompany = response.data.policy.lifeInServiceCompany != null ? Number(response.data.policy.lifeInServiceCompany) : null;


                    this.form.hasPhysicalDisabilityPolicy = Boolean(response.data.policy.hasPhysicalDisabilityPolicy);
                    this.form.physicalDisTypeOfCoverage = response.data.policy.physicalDisTypeOfCoverage != null ? Number(response.data.policy.physicalDisTypeOfCoverage) : null;
                    this.form.physicalDisServiceProvider = response.data.policy.physicalDisServiceProvider != null ? Number(response.data.policy.physicalDisServiceProvider) : null;
                    this.form.hasTransportationPolicy = Boolean(response.data.policy.hasTransportationPolicy);
                    this.form.transportationSupportAmount = response.data.policy.transportationSupportAmount != null ? Number(response.data.policy.transportationSupportAmount) : null;
                    this.form.transportationModel = response.data.policy.transportationModel != null ? Number(response.data.policy.transportationModel) : null;
                    this.form.transportationBusModel = response.data.policy.transportationBusModel != null ? Number(response.data.policy.transportationBusModel) : null;
                    this.form.transportationPickupDropoffModel = response.data.policy.transportationPickupDropoffModel != null ? Number(response.data.policy.transportationPickupDropoffModel) : null;
                    this.form.transportationMalesVsFemales = response.data.policy.transportationMalesVsFemales != null ? Number(response.data.policy.transportationMalesVsFemales) : null;

                    this.form.hasCarPolicy = Boolean(response.data.policy.hasCarPolicy);
                    this.form.carPolicyPurpose = response.data.policy.carPolicyPurpose != null ? Number(response.data.policy.carPolicyPurpose) : null;
                    this.form.carPolicyFunding = response.data.policy.carPolicyFunding != null ? Number(response.data.policy.carPolicyFunding) : null;
                    this.form.yearstoOwn = response.data.policy.yearstoOwn != null ? Number(response.data.policy.yearstoOwn) : null;
                    this.form.PercentPaidByEmployee = response.data.policy.percentPaidByEmployee != null ? Number(response.data.policy.percentPaidByEmployee) : null;
                    this.form.carSelctionBase = response.data.policy.carSelectionBase != null ? Number(response.data.policy.carSelectionBase) : null;
                    this.form.hasPayAdvancePolicy = Boolean(response.data.policy.hasPayAdvancePolicy);
                    this.form.salaryAdvancePercent = response.data.policy.salaryAdvancePercent != null ? Number(response.data.policy.salaryAdvancePercent) : null;
                    this.form.hasStaffLoanPolicy = Boolean(response.data.policy.hasStaffLoanPolicy);
                    this.form.salaryLoanPercent = response.data.policy.salaryLoanPercent != null ? Number(response.data.policy.salaryLoanPercent) : null;
                    this.form.connectivityPolicy = response.data.policy.hasConnectivityPolicy != null ? (response.data.policy.hasConnectivityPolicy == true ? 1 : 2) : null;
                    this.form.hasSchoolingFeesSupport = Boolean(response.data.policy.hasSchoolingFeesSupport);
                    this.form.schoolingSupportType = response.data.policy.schoolingSupportType != null ? Number(response.data.policy.schoolingSupportType) : null;
                    this.form.schoolingSupportValue = response.data.policy.schoolingSupportValue != null ? Number(response.data.policy.schoolingSupportValue) : null;
                    this.form.hasCapOPtion = response.data.policy.hasCapOPtion == true ? 'Yes' : 'No';
                    this.form.capValue = response.data.policy.capValue != null ? Number(response.data.policy.capValue) : null;
                    this.form.hasMarriageCashAward = Boolean(response.data.policy.hasMarriageCashAward);
                    this.form.marriageAwardType = response.data.policy.marriageAwardType != null ? Number(response.data.policy.marriageAwardType) : null;
                    this.form.marriageAwardValue = response.data.policy.marriageAwardValue != null ? Number(response.data.policy.marriageAwardValue) : null;
                    this.form.hasMarriageCapOPtion = response.data.policy.hasMarriageCapOPtion == true ? 'Yes' : 'No';
                    this.form.marriageCapValue = response.data.policy.marriageCapValue != null ? Number(response.data.policy.marriageCapValue) : null;
                    this.form.hasNewBornCashAward = Boolean(response.data.policy.hasNewBornCashAward);
                    this.form.newBornAwardType = response.data.policy.newBornAwardType != null ? Number(response.data.policy.newBornAwardType) : null;
                    this.form.newBornAwardValue = response.data.policy.newBornAwardValue != null ? Number(response.data.policy.newBornAwardValue) : null;
                    this.form.hasNewBornCapOPtion = response.data.policy.hasNewBornCapOPtion == true ? 'Yes' : 'No';
                    this.form.newBornCapValue = response.data.policy.newBornCapValue != null ? Number(response.data.policy.newBornCapValue) : null;
                    this.form.hasCashSupportforFirstDegRelDeath = Boolean(response.data.policy.hasCashSupportforFirstDegRelDeath);
                    this.form.relativeDeathSupportType = response.data.policy.relativeDeathSupportType != null ? Number(response.data.policy.relativeDeathSupportType) : null;
                    this.form.relativeDeathSupportValue = response.data.policy.relativeDeathSupportValue != null ? Number(response.data.policy.relativeDeathSupportValue) : null;
                    this.form.hasRelativeDeathCapOPtion = response.data.policy.hasRelativeDeathCapOPtion == true ? 'Yes' : 'No';
                    this.form.relativeDeathSupportCapValue = response.data.policy.relativeDeathSupportCapValue != null ? Number(response.data.policy.relativeDeathSupportCapValue) : null;
                    this.form.hasHajAndUmrahSupport = Boolean(response.data.policy.hasHajAndUmrahSupport);
                    this.form.hajAndUmrahSupportType = response.data.policy.hajAndUmrahSupportType != null ? Number(response.data.policy.hajAndUmrahSupportType) : null;
                    this.form.hajAndUmrahSupportValue = response.data.policy.hajAndUmrahSupportValue != null ? Number(response.data.policy.hajAndUmrahSupportValue) : null;
                    this.form.hashajAndUmrahCapOPtion = response.data.policy.hashajAndUmrahCapOPtion == true ? 'Yes' : 'No';
                    this.form.hajAndUmrahCapValue = response.data.policy.hajAndUmrahCapValue != null ? Number(response.data.policy.hajAndUmrahCapValue) : null;
                    this.form.hasMonetaryAwardPolicy = Boolean(response.data.policy.hasMonetaryAwardPolicy);
                    this.form.monetaryAwardType = response.data.policy.monetaryAwardType != null ? Number(response.data.policy.monetaryAwardType) : null;
                    this.form.monetaryAwardValue = response.data.policy.monetaryAwardValue != null ? Number(response.data.policy.monetaryAwardValue) : null;
                    this.form.hasNonMonetaryAwardPolicy = Boolean(response.data.policy.hasNonMonetaryAwardPolicy);
                    this.form.nonMonetaryAwardType = response.data.policy.nonMonetaryAwardType != null ? response.data.policy.nonMonetaryAwardType.split(',').map(Number) : [];
                    this.form.otherNonMonetaryAward = response.data.policy.otherNonMonetaryAward != null ? response.data.policy.otherNonMonetaryAward : null;
                    this.form.dayTimeRate_PV = response.data.policy.dayTimeRatePV != null ? Number(response.data.policy.dayTimeRatePV) : 0;
                    this.form.nightTimeRate_PV = response.data.policy.nightTimeRatePV != null ? Number(response.data.policy.nightTimeRatePV) : 0;
                    this.form.holidayRate_PV = response.data.policy.holidayRatePV != null ? Number(response.data.policy.holidayRatePV) : 0;
                    this.form.hasPayrollPolicy = Boolean(response.data.policy.hasPayrollPolicy);
                    this.form.PayrollOption_PV = response.data.policy.payrolOPtion != null ? Number(response.data.policy.payrolOPtion) : 0;
                    this.form.PayrolBank = response.data.policy.payrollBankId != null ? Number(response.data.policy.payrollBankId) : null;
                    this.form.carInsuranceProvider = response.data.policy.carInsuranceProvider != null ? Number(response.data.policy.carInsuranceProvider) : null;
                    this.form.selectedCar = response.data.policy.selectedCar != null ? Number(response.data.policy.selectedCar) : null;
                    this.form.lifeInServiceCompany = response.data.policy.lifeInServiceCompany != null ? Number(response.data.policy.lifeInServiceCompany) : null;

                }
            });
        }
    },
    mounted() {
        if (this.customerNumber > 0) {
            this.getPolicy();
        }
    }

}
</script>

<style></style>